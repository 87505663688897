import React from 'react';
import {Grid} from '@mui/material';
import {SlSocialInstagram} from 'react-icons/sl';
import {FaFacebookF} from 'react-icons/fa';
import {ImTwitter} from 'react-icons/im';
import {SiSnapchat} from 'react-icons/si';
import {BsLinkedin} from 'react-icons/bs';
import {allCommonText, footer} from '../../common/constants';
import {images} from '../../common/images';
import {publicPaths} from '../../common/routesNames';
import {useLocation} from 'react-router';
import './style.scss';

export const Footer = () => {
  const location = useLocation();
  const pagePath = location.pathname;
  return (
    <>
      <footer className="footerBox">
        {publicPaths.indexOf(pagePath) !== -1 && (
          <Grid container spacing={0}>
            <div
              className="footer-layout"
              style={{
                backgroundImage: `url(${images.footerBackgroundImage})`,
              }}>
              <Grid item xs={0} sm={0} md={0} lg={4} xl={4}>
                <div className="footer_conatiner">
                  <div className="footerLogo">
                    <img src={images.noDukLogo} alt={footer.nodukLogo} />
                    <span>{footer.footerSignUp}</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={0} sm={0} md={0} lg={4} xl={4}>
                <div className="footer_conatiner footerContent second-child">
                  <div className="footerNewsLetterBox">
                    <h5>{footer.newsLetter}</h5>
                    <p>{footer.subscribeNewsletter}</p>
                    <input
                      type="text"
                      placeholder={allCommonText.placeholders.emailAddress}
                    />
                    <button type="submit" className="btn btn-danger">
                      {footer.subscribe}
                    </button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={0} sm={0} md={0} lg={4} xl={4}>
                <div className="footer_conatiner footerContent fourth-Child">
                  <div className="footerSocialBox">
                    <img src={images.noDukLogo} alt={footer.nodukLogo} />
                    <span>{footer.nodukOfficial}</span>
                  </div>
                  <div className="officialVideosBox">
                    <Grid container spacing={1.5}>
                      {[
                        {
                          url: 'https://www.instagram.com/reel/CleXvswLejc/',
                          img: images.download1,
                        },
                        {
                          url: 'https://www.instagram.com/p/Ck_W8jIjoO7/',
                          img: images.download2,
                        },
                        {
                          url: 'https://www.instagram.com/p/Ck_Ww8Jj8z-/',
                          img: images.download3,
                        },
                        {
                          url: 'https://www.instagram.com/p/Ck_WeeLjRAt/',
                          img: images.download4,
                        },
                        {
                          url: 'https://www.instagram.com/reel/CleXvswLejc/',
                          img: images.download1,
                        },
                        {
                          url: 'https://www.instagram.com/reel/CleXvswLejc/',
                          img: images.download1,
                        },
                        {
                          url: 'https://www.instagram.com/p/CjGxQ9DOPah/',
                          img: images.download5,
                        },
                        {
                          url: 'https://www.instagram.com/p/CiaPY3yOndH/',
                          img: images.download8,
                        },
                        {
                          url: 'https://www.instagram.com/reel/CiAuXQ-pFJM/',
                          img: images.download7,
                        },
                        {
                          url: 'https://www.instagram.com/p/Ch-baSaOUt6/',
                          img: images.download6,
                        },
                        {
                          url: 'https://www.instagram.com/p/Ch7eHqDLRk_/',
                          img: images.download10,
                        },
                        {
                          url: 'https://www.instagram.com/p/Ch4692Srmg6/',
                          img: images.download11,
                        },
                      ].map((i, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}>
                            <div className="videoBox">
                              <a href={i.url} target="_blank" rel="noreferrer">
                                {/* <img src={i.img} alt="Nature" /> */}
                                <div
                                  className="link-images"
                                  style={{
                                    backgroundImage: `url(${i.img})`,
                                  }}></div>
                              </a>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                  <div className="loadButton">
                    <button className="btnLoadMore">{footer.loadMore}</button>
                    <div className="socialOption">
                      <div className="instaBox">
                        <a
                          href="https://www.instagram.com/accounts/login/"
                          target="_blank"
                          rel="noreferrer">
                          <span>
                            <SlSocialInstagram />
                          </span>
                          <span>{footer.followOnIsta}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className="socialOption">
                    <div className="instaBox">
                      <a
                        href="https://www.instagram.com/accounts/login/"
                        target="_blank"
                        rel="noreferrer">
                        <span>
                          <SlSocialInstagram />
                        </span>
                        <span>{footer.followOnIsta}</span>
                      </a>
                    </div>
                  </div> */}
                  <div className="socialIcon">
                    <a
                      href="https://www.facebook.com/login/"
                      target="_blank"
                      rel="noreferrer">
                      <span className="footerSocialIcon">
                        <FaFacebookF />
                      </span>
                    </a>
                    <a
                      href="https://www.facebook.com/login/"
                      target="_blank"
                      rel="noreferrer">
                      <span className="footerSocialIcon">
                        <SlSocialInstagram />
                      </span>
                    </a>

                    <a
                      href="https://twitter.com/i/flow/login"
                      target="_blank"
                      rel="noreferrer">
                      <span className="footerSocialIcon">
                        <ImTwitter />
                      </span>
                    </a>
                    <a
                      href="https://accounts.snapchat.com/accounts/login?client_id=ads-api&referrer=https%25253A%25252F%25252Fads.snapchat.com%25252Fsetup"
                      target="_blank"
                      rel="noreferrer">
                      <span className="footerSocialIcon">
                        <SiSnapchat />
                      </span>
                    </a>
                    <a href="#">
                      <span className="footerSocialIcon">
                        <BsLinkedin />
                      </span>
                    </a>
                  </div>
                </div>
              </Grid>
            </div>
          </Grid>
        )}
        <div className={`rightsBox`}>
          <span>
            {footer.rights}
            <a
              href="https://noduk.com/privacy/"
              target="_blank"
              rel="noreferrer"></a>
          </span>
        </div>
      </footer>
    </>
  );
};
