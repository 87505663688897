import React, {useState, useContext, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import {COUPON_TYPE, allCommonText} from '../../common/constants';
import './style.scss';
import {colors, images} from '../../common';
import {plans} from '../../common/constants';
import {
  AddPaymentMethod,
  GenericButton,
  GenericModal,
  InputField,
} from '../../components';
import ApplicationContext from '../../utils/context-api/context';
import {getPlanBadgColors, PlanCard} from './plan-card';
import {
  AddStripeSubId,
  CreateSubscription,
  getCheckoutItems,
  getPaymentDefaultCards,
  getPromoCodeInformation,
} from '../../utils/rest-services';
import {useStripe} from '@stripe/react-stripe-js';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';
import {AiFillCheckCircle} from 'react-icons/ai';

function Checkout() {
  const [selectedPlanId, setSelectedPlanId] = useState(1);
  const [addPaymentMethodPopUp, setAddPaymentMethodPopUp] = useState(false);
  const [plansCost, setPlansCost] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [existingCards, setExistingCards] = useState([]);
  const [popUpResponse, setPopUpResponse] = useState({
    type: 1,
    detail: '',
    title: '',
  });
  const [discountedContent, setDiscountedContent] = useState([]);
  const {selectedCard, isOpenPane} = useContext(ApplicationContext);
  const [actualDiscountByCoupon, setActualDiscountByCoupon] = useState('');
  const stripe = useStripe();
  let navigate = useNavigate();
  const [couponCode, setCouponCode] = useState('');
  const [showBadge, setShowBadge] = useState(false);
  const [recurringVal, setRecurringVal] = useState('');
  const [isValidDiscount, setIsValidDiscount] = useState(false);
  const [couponDuration, setCouponDuration] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    hiddenKeys: {plansId},
    popupEnums: {plansType, subscriptionPlanHeadings},
    serviceTypeEnums,
    heading: {discountsPlan},
  } = allCommonText;

  useEffect(() => {
    fetchCheckoutObject();
    getPaymentCards();
  }, []);
  useEffect(() => {
    if (!isOpenPane.isPaneOpen) {
      fetchCheckoutObject();
      getPaymentCards();
    }
  }, [isOpenPane]);

  const saveStripeID = async stripeSubsId => {
    let response = await AddStripeSubId(stripeSubsId);
    if (response && response?.data?.success) {
      const {
        data: {success},
      } = response || {};
      if (success) {
        setBtnLoader(false);
        setShowModal(true);
        setPopUpResponse({
          type: allCommonText.popupEnums.success,
          detail: allCommonText.successfullPayment.description,
        });
      }
    } else {
      setShowModal(true);
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        detail: response?.data?.description || allCommonText.checkoutError,
      });
    }
  };
  const removeCouponHandler = () => { 
    setCouponCode('');
    setDiscountedContent([]);
    setIsValidDiscount(false);
    setShowBadge(false);
    setCouponDuration(null);
   }
  const validateRecurring = (content, selectedPlanId) => {
    // debugger;
    let recurringCost = plansCost.monthlyCost.substring(1);
    let actualCost;
    let dicountAmountByPercent = content?.discountPercent / 100;
    if (content.discountAmount != null) {
      if (selectedPlanId === plansId.monthly) {
        // debugger;
        setIsValidDiscount(true);
        if (parseInt(recurringCost) > parseInt(content.minimumDiscountAmount)) {
          recurringCost = recurringCost - content?.discountAmount;
          setActualDiscountByCoupon(`$${content?.discountAmount}`);
          return recurringCost;
        }
      }
      if (selectedPlanId === plansId.biAnnual) {
        setIsValidDiscount(true);
        recurringCost = recurringCost * 6;
        if (parseInt(recurringCost) > parseInt(content.minimumDiscountAmount)) {
          recurringCost = recurringCost - content?.discountAmount;
          setActualDiscountByCoupon(`$${content?.discountAmount}`);
          return recurringCost;
        }
      }
      if (selectedPlanId === plansId.annual) {
        setIsValidDiscount(true);
        recurringCost = recurringCost * 12;
        if (parseInt(recurringCost) > parseInt(content.minimumDiscountAmount)) {
          recurringCost = recurringCost - content?.discountAmount;
          setActualDiscountByCoupon(`$${content?.discountAmount}`);
          return recurringCost;
        }
      }
    } else if (
      content.discountPercent != '' &&
      content.discountPercent != null
    ) {
      // If discount percent Coupon is being applied and plan is othen monthly than it should not be applicable
      if (selectedPlanId !== plansId.monthly) {
        setPopUpResponse({
          type: allCommonText.popupEnums.alert,
          detail: allCommonText.couponMessage.onlyMonthlyCoupon,
        });
      
        setShowModal(true);
        return null
      }

      if (selectedPlanId === plansId.monthly) {
        setIsValidDiscount(true);
        actualCost = recurringCost;
        if (parseInt(actualCost) > parseInt(content.minimumDiscountAmount)) {
          recurringCost = actualCost * dicountAmountByPercent;
          setActualDiscountByCoupon(`${content?.discountPercent}%`);
          actualCost = actualCost - recurringCost;
          if (actualCost === 0) {
            // If Recurring cost is equals to 0 then we should not be able to apply coupon code
            setPopUpResponse({
              type: allCommonText.popupEnums.error,
              detail: allCommonText.couponMessage.COUPON_GREATER_THAN,
            });
            setShowModal(true);
          }
          return actualCost;
        }
      }
      if (selectedPlanId === plansId.biAnnual) {
        setIsValidDiscount(true);
        actualCost = recurringCost * 6;
        if (parseInt(actualCost) > parseInt(content.minimumDiscountAmount)) {
          recurringCost = actualCost * dicountAmountByPercent;
          setActualDiscountByCoupon(`${content?.discountPercent}%`);
          actualCost = actualCost - recurringCost;
          if (actualCost === 0) {
            // If Recurring cost is equals to 0 then we should not be able to apply coupon code
            setPopUpResponse({
              type: allCommonText.popupEnums.error,
              detail: allCommonText.couponMessage.COUPON_GREATER_THAN,
            });
            setShowModal(true);
          }
          return actualCost;
        }
      }
      if (selectedPlanId === plansId.annual) {
        setIsValidDiscount(true);
        actualCost = recurringCost * 12;
        if (parseInt(actualCost) > parseInt(content.minimumDiscountAmount)) {
          recurringCost = actualCost * dicountAmountByPercent;
          setActualDiscountByCoupon(`${content?.discountPercent}%`);
          actualCost = actualCost - recurringCost;
          if (actualCost === 0) {
            // If Recurring cost is equals to 0 then we should not be able to apply coupon code
            setPopUpResponse({
              type: allCommonText.popupEnums.error,
              detail: allCommonText.couponMessage.COUPON_GREATER_THAN,
            });
            setShowModal(true);
          }
          return actualCost;
        }
      }
    } else if (
      content.duration == 'first' &&
      content.couponType === COUPON_TYPE.FIRST_MONTH
    ) {
      // debugger;
      setIsValidDiscount(true);
      const planCostWithoutDiscount = getRecurringTotalByItem(
        recurringCost,
        selectedPlanId,
      );
      const actualAmountToPay = planCostWithoutDiscount - recurringCost;

      // if (parseInt(recurringCost) > parseInt(content.minimumDiscountAmount)) {
      //   recurringCost = recurringCost - content?.discountAmount;
      setActualDiscountByCoupon(`100%`);
      //   return recurringCost;
      // }
      return actualAmountToPay;
    }
    setIsValidDiscount(false);
    setPopUpResponse({
      type: allCommonText.popupEnums.error,
      detail: allCommonText.couponMessage.amountLessThanMinAmount,
    });
    setShowModal(true);
    return null
  };

  const discountUsingCoupen = async code => {
    setLoading(true);
    let response = await getPromoCodeInformation(code);
    setLoading(false);
    const {success, content, description} = response || {};
    if (response && response?.success) {
      if (success) {
        setDiscountedContent(content);
        setCouponDuration(content?.duration);
        const recurringCost =validateRecurring(content, selectedPlanId)
        if(recurringCost){
          setRecurringVal(recurringCost);
          setShowBadge(true);
        }else{
         removeCouponHandler()
        }
      }
    } else {
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        detail: description,
      });
      setShowModal(true);
    }
  };
  const PlaceOrderHandler = () => {
    const subscriptionObject = {
      paymentMethodId: selectedCard?.paymentMethodId,
      subscriptionType: getPlanType(),
      saveCard: selectedCard.isCardSave,
      nameOnCard: selectedCard.cardName,
      newCard: selectedCard.newCard || false, // we will updat it for exisiting cards
      paymentPlatform: 1,
      promoCode: isValidDiscount ? couponCode : '',
      couponType: discountedContent?.couponType,
    };
    subscriptionWithExistingCard(subscriptionObject);
  };

  const subscriptionWithExistingCard = async subscriptionObject => {
    setBtnLoader(true);
    try {
      let subscripResult = await CreateSubscription(subscriptionObject);
      if (subscripResult.data.success != true) {
        setPopUpResponse({
          type: allCommonText.popupEnums.error,
          detail:
            subscripResult?.data?.description ||
            subscripResult?.data?.exceptionMessage ||
            allCommonText.checkoutError,
        });
        setShowModal(true);
        setBtnLoader(false);
        return;
      }
      const {
        data: {
          content: {clientSecret, paymethodId, stripeSubsId},
          success,
        },
      } = subscripResult || {};
      if (subscripResult && success) {
        const {paymentIntent, error} = await stripe.confirmCardPayment(
          clientSecret,
          {
            [`payment_method`]: paymethodId,
          },
        );

        if (paymentIntent) {
          saveStripeID(stripeSubsId); // incase you have confirm Payment from stripe
        } else {
          setPopUpResponse({
            type: allCommonText.popupEnums.error,
            detail: error.message || allCommonText.checkoutError,
          });
          setShowModal(true);
          setBtnLoader(false);
          return;
        }
      }
    } catch (error) {
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        detail: error.message || allCommonText.checkoutError,
      });
      setShowModal(true);
      setBtnLoader(false);
      return;
    }
  };

  const fetchCheckoutObject = async () => {
    let result = await getCheckoutItems();
    if (result && result?.data?.success) {
      const {
        data: {
          success,
          description,
          content: {
            plans: planResponse,
            totals: totalResponse,
            cartItemDetail: cartResponse,
          },
        },
      } = result || {};
      if (success) {
        setPlansCost(planResponse);
        setTotalCost(totalResponse);
        setCartItems(cartResponse);
      } else {
        setShowModal(true);
        setPopUpResponse({
          type: allCommonText.popupEnums.error,
          detail: description || allCommonText.checkoutError,
        });
      }
    } else {
      // do something when there is No-Success
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        detail: allCommonText.checkoutError,
      });
      setShowModal(true);
    }
  };

  const getPaymentCards = async () => {
    let result = await getPaymentDefaultCards();
    const {
      data: {success, content},
    } = result || {};

    if (result && success) {
      setExistingCards(content);
    } else {
      setExistingCards([]);
    }
  };

  const getRecurringTotalByItem = (value, planId) => {
    if (selectedPlanId === plansId.monthly || planId === plansId.monthly) {
      return value * 1;
    } else if (
      selectedPlanId === plansId.biAnnual ||
      planId === plansId.biAnnual
    ) {
      return value * 6;
    } else {
      return value * 12;
    }
  };
  const getDiscountOnPlans = () => {
    if (selectedPlanId == plansId.monthly) {
      return discountsPlan.monthly;
    } else if (selectedPlanId == plansId.biAnnual) {
      return discountsPlan.biannual;
    } else {
      return discountsPlan.annualy;
    }
  };
  const getPlanType = () => {
    if (selectedPlanId == plansId.monthly) {
      return allCommonText.planTypes.monthly;
    } else if (selectedPlanId == plansId.biAnnual) {
      return allCommonText.planTypes.biannual;
    } else {
      return allCommonText.planTypes.annual;
    }
  };
  const getTotalRecurring = () => {
    if (selectedPlanId === plansId.monthly) {
      return plansCost && plansCost.monthlyCost;
    } else if (selectedPlanId === plansId.biAnnual) {
      return plansCost && plansCost.biAnnualCost;
    } else {
      return plansCost && plansCost.annualCost;
    }
  };
  const actualRecurring = () => {
    let cost = plansCost.monthlyCost.substring(1);
    if (selectedPlanId === plansId.monthly) {
      return `$${cost}`;
    } else if (selectedPlanId === plansId.biAnnual) {
      return `$${(cost * 6)?.toFixed(2)}`;
    } else {
      return `$${(cost * 12)?.toFixed(2)}`;
    }
  };
  const IconSelector = id => {
    switch (id) {
      case serviceTypeEnums.vehicle:
        return <img src={images.vehicleV} alt={plans.vehicleIcon} />;
      case serviceTypeEnums.home:
        return <img src={images.homeV} alt={plans.homeIcon} />;
      case serviceTypeEnums.device:
        return <img src={images.devicesV} alt={plans.devicesIcon} />;
      case serviceTypeEnums.commercial:
        return <img src={images.commercialV} alt={plans.commercialIcon} />;
      case serviceTypeEnums.disaster:
        return <img src={images.diasasterR} alt={plans.commercialIcon} />;
    }
  };
  const DeductibleItem = ({IconId, deductible, recurring}) => {
    return (
      <div className="invoice-table-item">
        <span className="invoice-icon-container">{IconSelector(IconId)}</span>
        <span className="invoice-deductibles-container">{deductible}</span>
        <span>{recurring}</span>
      </div>
    );
  };
  const icrementByOne = value => {
    if (value && typeof value === 'string' && value?.includes('$')) {
      let stringWithoutDollarComma = value.replace(/\$|,/g, '');
      return `$${(parseFloat(stringWithoutDollarComma) + 1).toFixed(2)}`;
    } else {
      return `$${(parseFloat(value) + 1).toFixed(2)}`;
    }
  };
  const ItemInfo = ({text, price, text2, price2, couponType}) => {
    let discountType = '';
    switch (couponType) {
      case 'once':
        discountType = allCommonText.onlyForFirstInvoice;
        break;
      case 'forever':
        discountType = allCommonText.forever;
        break;
      default:
        discountType = '(first month only)';
        break;
    }

    

    return (
      <div className="total-amount-container">
      <div className="total-amount-child">
        <span>{text}</span>
        <div>
          <span className="discount-by-coupon-type">{price ?? 'N/A'}</span>
          {showBadge && couponType && (
            <span className="discount-type">{discountType}</span>
          )}
        </div>
      </div>
      {text2 && (
        <div className="total-amount-child">
          <span>{text2}</span>
          <span className="discount-by-coupon-type">{price2 } </span>
        </div>
      )}
    </div>
    
    );
  };

  const InvoiceCalculations = ({
    title1,
    price1,
    title2,
    price2,
    title3,
    price3,
  }) => {
    return (
      <div className="total-amount-container">
        {title1 != '' && (
          <div className="total-amount-child">
            <span>{title1}</span>
            <span className="discount-by-coupon-type">{price1 } </span>
          </div>
        )}
        {title2 != '' && (
          <div className="total-amount-child">
            <span>{title2}</span>
            <span className="discount-by-coupon-type">{price2} </span>
          </div>
        )}
        {title3 != '' && (
          <div className="total-amount-child">
            <span>{title3}</span>
            <span className="discount-by-coupon-type">{price3 } </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="main-container container">
      <div className="plans-container ">
        <span className="caption-title">
          <span>NO DUK</span>
          {"has plans that won't break the bank!"}
        </span>
        <div className="cards-container mt-2">
          <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={4} xs={12}>
              <div className="card-container ">
                <PlanCard
                  showBadge={showBadge}
                  type={1}
                  onCardPress={() => {
                    setSelectedPlanId(plansId.monthly);
                    {
                      discountedContent?.length != 0 &&
                        setRecurringVal(
                          validateRecurring(discountedContent, plansId.monthly),
                        );
                    }
                  }}
                  check={selectedPlanId === plansId.monthly}
                  title={plansType.monthly}
                  infoOne={allCommonText.labels.renews.monthly}
                  infoSecond={discountsPlan.monthly}
                  price={plansCost && plansCost.monthlyCost}
                  key={1}
                  planType={1}
                />
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
              <div className="card-container ">
                <PlanCard
                  showBadge={showBadge}
                  type={2}
                  check={selectedPlanId === plansId.biAnnual}
                  onCardPress={() => {
                    setSelectedPlanId(plansId.biAnnual);
                    {
                      discountedContent?.length != 0 &&
                        setRecurringVal(
                          validateRecurring(
                            discountedContent,
                            plansId.biAnnual,
                          ),
                        );
                    }
                  }}
                  title={plansType.biAnnual}
                  infoOne={allCommonText.labels.renews.biannual}
                  infoSecond={
                    showBadge
                      ? discountsPlan.monthly
                      : `${discountsPlan.biannual} ${allCommonText.discount}`
                  }
                  price={
                    showBadge
                      ? `$${(plansCost.monthlyCost.substring(1) * 6).toFixed(
                          2,
                        )}`
                      : plansCost && plansCost.biAnnualCost
                  }
                  key={2}
                  planType={2}
                />
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
              <div className="card-container ">
                <PlanCard
                  showBadge={showBadge}
                  type={2}
                  check={selectedPlanId === plansId.annual}
                  onCardPress={() => {
                    setSelectedPlanId(plansId.annual);
                    {
                      discountedContent?.length != 0 &&
                        setRecurringVal(
                          validateRecurring(discountedContent, plansId.annual),
                        );
                    }
                  }}
                  title={plansType.annual}
                  infoOne={allCommonText.labels.renews.yearly}
                  infoSecond={
                    showBadge
                      ? discountsPlan.monthly
                      : `${discountsPlan.annualy} ${allCommonText.discount}`
                  }
                  price={
                    showBadge
                      ? `$${(plansCost.monthlyCost.substring(1) * 12).toFixed(
                          2,
                        )}`
                      : plansCost && plansCost.annualCost
                  }
                  key={3}
                  planType={3}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <section className="invoice-container">
          <div className="invoice-top-wrapper w-100">
            <div className="invoice-title">
              <span>{allCommonText.popupEnums.subscriptionSummary} </span>
              <div
                className={`invoice-type ${
                  getPlanBadgColors(selectedPlanId).background
                } `}>
                <span>{getPlanType()} </span>
              </div>
            </div>
          </div>
          <div className="invoice-table-heading">
            <span>{subscriptionPlanHeadings.item}</span>
            <span>{subscriptionPlanHeadings.deductibles}</span>
            <span>{subscriptionPlanHeadings.recurring}</span>
          </div>
          {cartItems &&
            cartItems?.map((value, index) => (
              <DeductibleItem
                key={index}
                IconId={value.serviceTypeId}
                deductible={`$${value.deductibleAmount.toFixed(2)}`}
                recurring={`$${getRecurringTotalByItem(value.cost).toFixed(2)}`}
              />
            ))}
          <ItemInfo
            text={allCommonText.toalDeductibles}
            price={totalCost && totalCost.totalDeductibles}
          />
          <div className="checkout-cont">
            <span className="coupon-code">
              {allCommonText.buttonTexts.couponCode}
            </span>
            <br />
            <span className="promo-note">{allCommonText.couponNote}</span>
            <div className="checkout-inner">
              <div className="checkout-input-field">
                <InputField
                  disabled={showBadge}
                  value={couponCode}
                  placeholder={allCommonText.buttonTexts.enterCode}
                  handleChange={e => setCouponCode(e.target.value)}
                />
              </div>
              <div className="checkout-apply-btn">
                <GenericButton
                  loading={loading}
                  disable={couponCode?.length == 0 || showBadge}
                  onPress={() => discountUsingCoupen(couponCode)}
                  buttonText={allCommonText.buttonTexts.apply}
                />
              </div>
              {showBadge && (
                <div className="badge-style">
                  <InputField disabled={true} value={couponCode} />
                  <button
                    className="clear-button"
                    onClick={() => {
                      setShowBadge(false);
                      setCouponCode('');
                      setDiscountedContent([]);
                      setIsValidDiscount(false);
                      setCouponDuration(null);
                    }}>
                    <span>X</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <ItemInfo
            text={allCommonText.discount}
            price={showBadge ? actualDiscountByCoupon : getDiscountOnPlans()}
            couponType={couponDuration}
          />

          <InvoiceCalculations
            title1={allCommonText.currentInvoice}
            price1={
              showBadge ? `$${recurringVal?.toFixed(2)}` : getTotalRecurring()
            }
            title2={allCommonText.platformFee}
            price2={'$1.00'}
            title3={allCommonText.totalCurrentInv}
             price3={
              showBadge
                ? icrementByOne(recurringVal)
                : icrementByOne(getTotalRecurring())
            }
          />

          {/* {showBadge && couponDuration == allCommonText.once && (
            <ItemInfo
              text={allCommonText.currentInvoice}
              text2={
                couponDuration == allCommonText.once &&
                allCommonText.futureInvoice
              }
              price={
                showBadge && recurringVal?.length != 0
                  ? `$${recurringVal?.toFixed(2)}`
                  : getDiscountOnPlans()
              }
              price2={showBadge ? actualRecurring() : getTotalRecurring()}
            />
          )} */}
          <ItemInfo
            text={allCommonText.futureInvoice}
            price={
              showBadge
                ? couponDuration === 'forever'
                  ? `$${recurringVal?.toFixed(2)}`
                  : actualRecurring()
                : getTotalRecurring()
            }
          />

          <div className="bottom-note">
            <span>
              <span>{allCommonText.note}</span>
              {allCommonText.nodukInfo}
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={allCommonText.urls.home}>
                  {allCommonText.learnMore}
                </a>
              </span>
            </span>
          </div>
        </section>
        <div className="add-payment-method">
          <span className="payment-card-type">
            {selectedCard && (
              <i
                class={`fa-brands fa-cc-${selectedCard.cardType}`}
                style={{color: colors.primary, fontSize: '50px'}}></i>
            )}
            <span className="card-payment-info">
              {selectedCard
                ? `*** *** *** ${selectedCard.last4}`
                : allCommonText.selectPayment}
            </span>
          </span>
          {selectedCard?.isCardSave && (
            <div className="default-card-check">
              <AiFillCheckCircle color={colors.green} size={20} />
              <span>{allCommonText.cardSavedAsDefault}</span>
            </div>
          )}
          <div
            onClick={() => {
              setAddPaymentMethodPopUp(true);
            }}
            className="add-payment-method">
            <span>
              {selectedCard
                ? allCommonText.buttonTexts.change
                : allCommonText.buttonTexts.add}
            </span>
          </div>
        </div>
        <div className="pay-now-container">
          <GenericButton
            id="checkout-pay-now"
            onPress={() => {
              PlaceOrderHandler();
            }}
            disable={selectedCard == null}
            buttonText={allCommonText.buttonTexts.payNow}
            loading={btnLoader}
          />
        </div>
      </div>
      <AddPaymentMethod
        handleClose={() => {
          setAddPaymentMethodPopUp(false);
        }}
        show={addPaymentMethodPopUp}
        existingCards={existingCards}
      />
      <GenericModal
        type={popUpResponse.type}
        show={showModal}
        btnText={allCommonText.ok}
        // title={allCommonText.successfullPayment.title}
        body={
          popUpResponse.type === allCommonText.popupEnums.success ? (
            <div className="payment-success-popup">
              {/* <div> {allCommonText.successfullPayment.paymentReceived}</div> */}
              {allCommonText.successfullPayment.description}
              <a href={`mailto:${allCommonText.urls.info}`}>
                {allCommonText.urls.info}
              </a>
            </div>
          ) : (
            popUpResponse.detail
          )
        }
        handleClose={() => {
          if (popUpResponse.type === allCommonText.popupEnums.success) {
            setShowModal(false);
            navigate({
              pathname: path.plans,
            });
          } else {
            setShowModal(false);
            removeCouponHandler()
          }
        }}
      />
    </div>
  );
}

export default Checkout;
