import React, {useState, useEffect} from 'react';
import {allCommonText} from '../../../common/constants';
import {
  CustomizedSearch,
  InvoiceAndSubscriptionDetails,
  Loader,
  PolicyCard,
} from '../../../components';
import './styles.scss';
import {getAllRefund} from '../../../utils/rest-services';
import Pagination from '@mui/material/Pagination';
import {planCategory} from '../policy-and-claims';
import {useNavigate} from 'react-router';
import {path} from '../../../common/routesNames';

const Refund = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [selectedId, setSelectedId] = useState(0);
  const [refundList, setRefundList] = useState([]);
  const [refundDetailPopup, setRefundDetailPopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [currentRefundType, setCurrentRefundType] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();
  useEffect(() => {
    let payload = {
      pageNo: currentPage,
      rowsPerPage: 10,
      status: currentStatus,
      id: 0,
      subscriptionId: 0,
      refundType: currentRefundType,
    };
    getAllRefundDetails(payload);
  }, [currentPage]);

  const getAllRefundDetails = async payload => {
    setPageLoader(true);
    let response = await getAllRefund(payload);
    if (response && response.data.success) {
      const {
        data: {
          content: {list, totalPages},
        },
      } = response;
      if (list && list?.length > 0) {
        setRefundList(list);
        setTotalPages(totalPages);
      } else {
        setRefundList([]);
        setTotalPages(1);
      }
      setPageLoader(false);
    }
  };
  const refundStatuBadge = status => {
    if (status === allCommonText.refundCategory.failed) {
      return 'rounded-pill cancelled-background';
    } else if (status === allCommonText.refundCategory.pending) {
      return 'rounded-pill pending-background';
    } else {
      return 'rounded-pill settled-background';
    }
  };

  const getStatusByText = text => {
    if (text == allCommonText.labels.refundStatus.pending) {
      return 1;
    } else if (text == allCommonText.labels.refundStatus.succeed) {
      return 2;
    } else if (text == allCommonText.labels.refundStatus.failed) {
      return 3;
    } else {
      return 0;
    }
  };
  const getTypeByText = text => {
    if (text == allCommonText.labels.refundTypes.full) {
      return 2;
    } else if (text == allCommonText.labels.refundTypes.partial) {
      return 1;
    } else {
      return 0;
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const onSearchHandler = async (
    refundId,
    subscriptionId,
    status,
    refundType,
    isClear,
  ) => {
    let payload;
    if (isClear) {
      setCurrentStatus(0);
      setCurrentRefundType(0);
      payload = {
        pageNo: 1,
        rowsPerPage: 10,
        id: 0,
        subscriptionId: 0,
        status: 0,
        refundType: 0,
      };
    } else {
      setCurrentStatus(getStatusByText(status));
      setCurrentRefundType(getTypeByText(refundType));
      payload = {
        pageNo: 1,
        rowsPerPage: 10,
        id: refundId == '' ? 0 : refundId.replace(/\D/g, ''),
        subscriptionId:
          subscriptionId == '' ? 0 : subscriptionId.replace(/\D/g, ''),
        status: getStatusByText(status),
        refundType: getTypeByText(refundType),
      };
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await getAllRefundDetails(payload);
    setBtnLoader(false);
  };
  return (
    <>
      <div className="main-refund-container">
        <h3>{allCommonText.profile.refund}</h3>
        <CustomizedSearch
          firstLabel={allCommonText.labels.refundID}
          firstPlaceholder={` ${allCommonText.placeholders.refundIdNumber}`}
          firstPrefixValue={'ref_'}
          secondBox
          secondLabel={allCommonText.labels.subscriptionId}
          subscriptionType
          secondPlaceholder={`${allCommonText.labels.enter} ${allCommonText.placeholders.subscriptionId}`}
          subscriptionTypeLabel={allCommonText.labels.refundType}
          subscriptionStatus={[
            allCommonText.labels.refundTypes.all,
            allCommonText.labels.refundTypes.partial,
            allCommonText.labels.refundTypes.full,
          ]}
          thirdLabel={allCommonText.labels.viewByStatus}
          status={[
            allCommonText.labels.refundStatus.all,
            allCommonText.labels.refundStatus.succeed,
            allCommonText.labels.refundStatus.pending,
            allCommonText.labels.refundStatus.failed,
          ]}
          title={allCommonText.heading.searchRefund}
          btnLoader={btnLoader}
          onClearFilterHandler={() => {
            onSearchHandler('', '', '', '', true);
          }}
          onSearchBtnPress={(refundId, subscriptionId, refundType, status) =>
            onSearchHandler(refundId, subscriptionId, refundType, status)
          }
        />
        {pageLoader ? (
          <div className="no-invoice-container">
            <Loader size={40} />
          </div>
        ) : (
          [
            refundList?.length == 0 ? (
              <div className="no-invoice-container">
                <span>{allCommonText.labels.noRefund}</span>
              </div>
            ) : (
              refundList?.map((item, index) => {
                const {
                  refundAmount,
                  refundId,
                  subscriptionType,
                  refundDate,
                  refundStatus,
                  viewSubscriptionId,
                } = item || {};
                return (
                  <PolicyCard
                    key={index}
                    cardType={allCommonText.viewCardType.refund}
                    onDetailBtnPress={() => {
                      setSelectedId(refundId);
                      navigate({
                        pathname: path.refundDetail,
                        search: `?id=${refundId}`,
                      });
                      // setRefundDetailPopup(true);
                    }}
                    planIconTitle={<span>{refundId}</span>}
                    badge={
                      <span class={refundStatuBadge(refundStatus)}>
                        {refundStatus.charAt(0).toUpperCase() +
                          refundStatus.slice(1)}
                      </span>
                    }
                    cost={
                      <span className={planCategory(subscriptionType)}>
                        {subscriptionType}
                      </span>
                    }
                    LeftTopDetails={
                      <div className="details-item">
                        <span>{allCommonText.labels.refundID}</span>
                        <span>
                          {refundId === ''
                            ? allCommonText.policyClaims.notAvailable
                            : refundId}
                        </span>
                      </div>
                    }
                    LeftBottomDetails={
                      <div className="details-item">
                        <span>{allCommonText.labels.subscriptionId}</span>
                        <span>
                          {viewSubscriptionId === ''
                            ? allCommonText.policyClaims.notAvailable
                            : viewSubscriptionId}
                        </span>
                      </div>
                    }
                    rightTopDetails={
                      <div className="details-item">
                        <span>{allCommonText.labels.refundDate}</span>
                        <span>
                          {refundDate === ''
                            ? allCommonText.policyClaims.notAvailable
                            : refundDate.replace(/\//g, '-')}
                        </span>
                      </div>
                    }
                    rigthBottomDetails={
                      <div className="details-item">
                        <span>{allCommonText.labels.amount}</span>
                        <span>
                          {refundAmount === ''
                            ? allCommonText.policyClaims.notAvailable
                            : refundAmount}
                        </span>
                      </div>
                    }
                  />
                );
              })
            ),
          ]
        )}
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <Pagination
            page={currentPage}
            color={'secondary'}
            onChange={handlePageChange}
            count={totalPages}
          />
        </div>
      )}
      {refundDetailPopup && (
        <InvoiceAndSubscriptionDetails
          show={refundDetailPopup}
          refundId={selectedId}
          handelClose={() => setRefundDetailPopup(false)}
        />
      )}
    </>
  );
};
export default Refund;
