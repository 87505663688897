import React, {useContext, useEffect} from 'react';
import {IoIosLogOut} from 'react-icons/io';
import {CiUser} from 'react-icons/ci';
import {Grid} from '@mui/material';
import {MdEmail} from 'react-icons/md';
// import {GiUmbrella} from 'react-icons/gi';
// import {ImMenu} from 'react-icons/im';
import {TfiMenuAlt} from 'react-icons/tfi';
import {FaUser} from 'react-icons/fa';
import {FaCar} from 'react-icons/fa';
import {FaMobile} from 'react-icons/fa';
import {HiOutlineChartBarSquare} from 'react-icons/hi2';
import {FaHome} from 'react-icons/fa';
import {FaTruck} from 'react-icons/fa';
import {BiMinus} from 'react-icons/bi';
import {images} from '../../common/images';
import {allCommonText, header} from '../../common/constants';
import {CartSlider} from '../cart-slider';
import ApplicationContext from '../../utils/context-api/context';
import {getCartCount, removeDeviceTokenAPI} from '../../utils/rest-services';
import {useLocation, useNavigate} from 'react-router';
import {
  pagesWithoutAccountButton,
  pagesWithoutHeader,
  path,
} from '../../common/routesNames';
import {
  clearStorage,
  getDeviceToken_,
  getToken,
} from '../../utils/localstorage';
import './style.scss';

export const Header = () => {
  const {setSelectedTab} = useContext(ApplicationContext);
  const isLoggedIn = getToken() !== '';
  let navigate = useNavigate();
  const {
    cartCount,
    setCartCount,
    notificationCount,
    isAccountButton,
    setIsAccountButton,
  } = useContext(ApplicationContext);
  const location = useLocation();
  const pagePath = location.pathname;

  useEffect(() => {
    if (cartCount == '' && pagesWithoutHeader.indexOf(pagePath) == -1) {
      fetchCartCount();
    }
  }, [isLoggedIn]);
  const performLogout = async () => {
    await removeDeviceToken();
    clearStorage();
    navigate(path.login, {replace: true});
  };

  const removeDeviceToken = async () => {
    const token = getDeviceToken_();
    await removeDeviceTokenAPI(token);
  };

  const fetchCartCount = async () => {
    let response = await getCartCount();
    if (response && response.success) {
      const {content} = response;
      setCartCount(content);
    }
  };

  return (
    <div className="headerBox">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
              <div className="log-box">
                <div
                  className="logo"
                  onClick={() => {
                    navigate(path.plans, {replace: true});
                  }}
                  style={{
                    backgroundImage: `url("${images.noDukLogo}")`,
                  }}></div>
              </div>
              <div className="sliderButton-smallScreens-cart">
                {pagesWithoutHeader.indexOf(pagePath) == -1 && (
                  <li>
                    <CartSlider />
                  </li>
                )}
              </div>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a
                      class="marginClass"
                      aria-current="page"
                      href={allCommonText.urls.home}>
                      <span className="linkLables">{header.home}</span>
                    </a>
                  </li>

                  <div class="dropdown">
                    <li class="nav-item">
                      <a
                        class=""
                        href={allCommonText.deductablePlans.deductable}>
                        <span className="headerIcon d-inline">
                          {/* {<GiUmbrella />} */}
                        </span>
                        <span className="linkLables">
                          {header.deductiblePlans}
                        </span>
                        <div class="dropdown-content">
                          <a
                            href={
                              allCommonText.deductablePlans.autoDeductablePlan
                            }
                            className="content-material">
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              <span>
                                <FaCar />
                              </span>
                              {header.autoPlan}
                            </div>
                          </a>
                          <a
                            href={
                              allCommonText.deductablePlans.homeDeductablePlan
                            }>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              <span>
                                <FaHome />
                              </span>
                              {header.homePlan}
                            </div>
                          </a>
                          <a
                            href={
                              allCommonText.deductablePlans
                                .commercialDeductablePlan
                            }>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              <span>
                                <FaTruck />
                              </span>
                              {header.commercialPlan}
                            </div>
                          </a>
                          <a
                            href={
                              allCommonText.deductablePlans.phoneDeductablePlan
                            }>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              <span>
                                <FaMobile />
                              </span>
                              {header.phonePLan}
                            </div>
                          </a>
                        </div>
                      </a>
                    </li>
                  </div>
                  <div class="dropdown">
                    <li class="nav-item">
                      <a class="" href="#">
                        <span className="headerIcon d-inline">
                          {<TfiMenuAlt />}
                        </span>
                        <span className="linkLables">{header.joinTheTeam}</span>
                        <div class="dropdown-content">
                          {/* 1 */}
                          <a href={allCommonText.affiliates.affiliate}>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              {header.bcomeAffiliates}
                            </div>
                          </a>
                          {/* 2 */}
                          <a href={allCommonText.affiliates.insuranceAgent}>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              {header.insuranceAgent}
                            </div>
                          </a>
                          {/* 3 */}
                          <a href={allCommonText.affiliates.salesAmbassador}>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              {header.salesAmbassador}
                            </div>
                          </a>
                          {/* 4 */}
                          <a href={allCommonText.affiliates.agency}>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              {header.agency}
                            </div>
                          </a>
                          {/* 5 */}
                          <a
                            href={allCommonText.affiliates.distributionChannel}>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              {header.distributionChannel}
                            </div>
                          </a>
                          {/* 6 */}
                          <a href={allCommonText.affiliates.myAccount}>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              {header.myAccount}
                            </div>
                          </a>
                        </div>
                      </a>
                    </li>
                  </div>
                  <li class="nav-item">
                    <a
                      class="marginClass"
                      aria-current="page"
                      href={allCommonText.urls.blog}>
                      <span className="linkLables">{header.blog}</span>
                    </a>
                  </li>
                  <div class="dropdown">
                    <li class="nav-item">
                      <a class="marginClass" href={allCommonText.aboutUs.about}>
                        <span className="linkLables">{header.aboutUS}</span>
                        <div class="dropdown-content">
                          <a href={allCommonText.aboutUs.testimonials}>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem">
                              {header.testimonials}
                            </div>
                          </a>
                          <a href={allCommonText.aboutUs.faqs}>
                            <div className="dropdownSecondryItem">
                              <span className="minusSign">
                                <BiMinus />
                              </span>
                            </div>
                            <div className="dropdownPrimaryItem ">
                              {header.faqs}
                            </div>
                          </a>
                        </div>
                      </a>
                    </li>
                  </div>
                  <li class="nav-item">
                    <a class="" href="#">
                      <span className="headerIcon d-inline">{<MdEmail />}</span>
                      <span className="linkLables">{header.contactUs}</span>
                    </a>
                  </li>
                  {pagesWithoutAccountButton.indexOf(pagePath) == -1 && (
                    <div className="sliderButton-largeScreen-cart">
                      <li>
                        <div
                          onClick={() => {
                            navigate(path.notifications);
                          }}
                          className="notificationBtn">
                          <span>
                            <img src={images.headerNotify} />
                          </span>
                          {notificationCount && notificationCount > 0 ? (
                            <div className="notify-count-header">
                              <span>
                                {notificationCount === 0
                                  ? ''
                                  : `${
                                      notificationCount >= 100
                                        ? '99+'
                                        : `${notificationCount}`
                                    }`}
                              </span>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </li>
                    </div>
                  )}
                  <div
                    className="sliderButton-largeScreen-cart"
                    onClick={() => {
                      setIsAccountButton(false);
                    }}>
                    {pagesWithoutHeader.indexOf(pagePath) == -1 && (
                      <li>
                        <CartSlider />
                      </li>
                    )}
                  </div>
                  {
                    <div className="account-button">
                      {pagesWithoutAccountButton.indexOf(pagePath) == -1 && (
                        <button
                          type="button"
                          className="accountButton"
                          onClick={() => {
                            setIsAccountButton(!isAccountButton);
                          }}>
                          <span>
                            <FaUser />
                          </span>
                          <span>{header.myAccount}</span>
                        </button>
                      )}

                      {isAccountButton && (
                        <div class="dropdown-account">
                          <div
                            className="dropdown-account-content"
                            onClick={() => {
                              navigate({pathname: path.dashboard});
                              setIsAccountButton(false);
                              setSelectedTab(0);
                            }}>
                            <span>
                              <HiOutlineChartBarSquare size={20} />
                            </span>
                            <span>{allCommonText.dashboard.dashboard}</span>
                          </div>
                          <div
                            className="dropdown-account-content"
                            onClick={() => {
                              navigate({pathname: path.profile});
                              setIsAccountButton(false);
                              setSelectedTab(6);
                            }}>
                            <span>
                              <CiUser size={20} />
                            </span>
                            <span>{header.profile}</span>
                          </div>
                          <div
                            className="dropdown-account-content"
                            onClick={() => {
                              performLogout();
                              setIsAccountButton(false);
                            }}>
                            <span>
                              <IoIosLogOut size={20} className="iconsTester" />
                            </span>
                            <span>{header.logout}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </ul>
              </div>
            </div>
          </nav>
        </Grid>
      </Grid>
    </div>
  );
};
