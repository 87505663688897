import * as Yup from 'yup';
import {allCommonText, signinText, signupText} from './constants';

const {
  errorPlaceholders: {email, password, newPassword},
} = signinText;
const {
  errorPlaceholders: {
    firstName,
    lastName,
    emailv2,
    phone,
    confirm,
    confirmPassRequires,
    address,
    city,
    state,
    zip,
    reason,
  },
} = signupText;
const {
  maker,
  modal,
  address: addressText,
  otherCarrier,
  brand,
  model,
  otherDeductibles,
  deductibles,
  carrier: carrierText,
  pleaseEnter,
  pleaseSelect,
  validOtherDeductibles,
  errorMessage,
  errorMessage: {
    invalidEmail,
    driverLicense,
    registerationDoc,
    insuranceError,
    calimInfoError,
    incidentReportError,
    minimumDeductibles,
  },
  dateLoss,
  dateField,
  fieldsValue,
} = allCommonText;

export const signinSchema = Yup.object({
  email: Yup.string().email(invalidEmail).required(email),
  password: Yup.string().required(password),
});
export const deleteAccountSchema = Yup.object({
  reason: Yup.string().required(reason),
  password: Yup.string().required(password),
});
export const resetSchema = Yup.object({
  password: Yup.string().min(6).required(password),
  confirmPassword: Yup.string()
    .required(confirmPassRequires)
    .oneOf([Yup.ref('password'), null], confirm),
});
export const forgotPasswordSchema = Yup.object({
  email: Yup.string().email(invalidEmail).required(email),
});

export const signUpStep1Schema = Yup.object({
  firstName: Yup.string().required(firstName),
  lastName: Yup.string().required(lastName),
  email: Yup.string().email(invalidEmail).required(emailv2),
  // dob: Yup.string().required(dob),
  phone: Yup.string().min(10).required(phone),
});

export const signUpStep2Schema = Yup.object({
  address: Yup.string().required(address),
  city: Yup.string().required(city),
  state: Yup.string().required(state),
  zip: Yup.string().required(zip),
});

export const signUpStep3Schema = Yup.object({
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required(password),
  confirmPassword: Yup.string()
    .required(confirmPassRequires)
    .oneOf([Yup.ref('password'), null], confirm),
});

export const addVehicleSchema = (isOtherDeductibles, isOtherCarrier) => {
  const schemaObj = Yup.object({
    carrier: Yup.object().nullable().required(`${pleaseSelect} ${carrierText}`),
    maker: Yup.object().nullable().required(`${pleaseSelect} ${maker}`),
    model: Yup.object().nullable().required(`${pleaseSelect} ${modal}`),
    otherCarrier: Yup.string().trim().nullable(),
    otherDeductibles: Yup.string().trim().nullable(),
    // year: Yup.string().required(`${pleaseEnter} ${year}`),
    year: Yup.number()
      .typeError(errorMessage.year.number)
      .integer(errorMessage.year.integer)
      .min(1900, errorMessage.year.min)
      .max(2023, errorMessage.year.max)
      .required(errorMessage.year.required),
    deductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} ${deductibles}`),
  });
  if (isOtherCarrier) {
    schemaObj.fields.otherCarrier = Yup.string()
      .trim()
      .required(`${pleaseEnter} ${otherCarrier}`);
  }
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, minimumDeductibles)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${pleaseEnter} ${otherDeductibles}`)
      .test('onlydigits', validOtherDeductibles, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};

export const editVehicleSchema = isOtherDeductibles => {
  const schemaObj = Yup.object({
    otherDeductibles: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} ${deductibles}`),
  });
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.string()
      .matches(/^[1-9]\d*(\.\d+)?$/, validOtherDeductibles)
      .matches(/^\d*(\.\d{0,2})?$/, 'Only 2 digits are required after decimal')
      .trim()
      .required(`${pleaseEnter} ${otherDeductibles}`);
  }
  return schemaObj;
};
export const editDeductibleSchema = isOtherDeductibles => {
  const schemaObj = Yup.object({
    otherDeductibles: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} ${deductibles}`),
  });
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, minimumDeductibles)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${pleaseEnter} ${otherDeductibles}`)
      .test('onlydigits', validOtherDeductibles, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};

export const editHomeSchema = isOtherDeductibles => {
  const schemaObj = Yup.object({
    otherDeductibles: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} ${deductibles}`),
  });
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.string()
      .trim()
      .matches(/^[1-9]\d*(\.\d+)?$/, validOtherDeductibles)
      .matches(/^\d*(\.\d{0,2})?$/, 'Only 2 digits are required after decimal')
      .required(`${pleaseEnter} ${otherDeductibles}`);
  }
  return schemaObj;
};

export const addHomeSchema = (isOtherDeductibles, isOtherCarrier) => {
  const schemaObj = Yup.object({
    carrier: Yup.object().nullable().required(`${pleaseSelect} ${carrierText}`),
    otherCarrier: Yup.string().trim().nullable(),
    otherDeductibles: Yup.string().trim().nullable(),
    address: Yup.string().required(`${pleaseEnter} ${addressText}`),
    city: Yup.string().required(`${pleaseEnter} ${fieldsValue.city}`),
    state: Yup.string().required(`${pleaseEnter} ${fieldsValue.state}`),
    zip: Yup.string().required(`${pleaseEnter} ${fieldsValue.zip}`),
    deductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} ${deductibles}`),
  });
  if (isOtherCarrier) {
    schemaObj.fields.otherCarrier = Yup.string()
      .trim()
      .required(`${pleaseEnter} ${otherCarrier}`);
  }
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, minimumDeductibles)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${pleaseEnter} ${otherDeductibles}`)
      .test('onlydigits', validOtherDeductibles, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};
export const addHomeSchemaWithDisaster = (
  isOtherDeductibles,
  isOtherCarrier,
) => {
  const schemaObj = Yup.object({
    carrier: Yup.object().nullable().required(`${pleaseSelect} ${carrierText}`),
    otherCarrier: Yup.string().trim().nullable(),
    otherDeductibles: Yup.string().trim().nullable(),
    address: Yup.string().required(`${pleaseEnter} ${addressText}`),
    city: Yup.string().required(`${pleaseEnter} ${fieldsValue.city}`),
    state: Yup.string().required(`${pleaseEnter} ${fieldsValue.state}`),
    zip: Yup.string().required(`${pleaseEnter} ${fieldsValue.zip}`),
    deductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} ${deductibles}`),
    disasterDeductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} disaster ${deductibles}`),
  });
  if (isOtherCarrier) {
    schemaObj.fields.otherCarrier = Yup.string()
      .trim()
      .required(`${pleaseEnter} ${otherCarrier}`);
  }
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, minimumDeductibles)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${pleaseEnter} ${otherDeductibles}`)
      .test('onlydigits', validOtherDeductibles, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};

export const editDevicesSchema = isOtherDeductibles => {
  const schemaObj = Yup.object({
    otherCarrier: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} ${deductibles}`),
    otherDeductibles: Yup.string().trim().nullable(),
  });
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.string()
      .trim()
      .matches(/^[1-9]\d*(\.\d+)?$/, validOtherDeductibles)
      .matches(/^\d*(\.\d{0,2})?$/, 'Only 2 digits are required after decimal')
      .required(`${pleaseEnter} ${otherDeductibles}`);
  }
  return schemaObj;
};

export const addDeviceSchema = (isOtherDeductibles, isOtherCarrier) => {
  const schemaObj = Yup.object({
    carrier: Yup.object().nullable().required(`${pleaseSelect} ${carrierText}`),
    otherCarrier: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} ${deductibles}`),
    otherDeductibles: Yup.string().trim().nullable(),
    brand: Yup.string().required(`${pleaseEnter} ${brand}`),
    model: Yup.string().required(`${pleaseEnter} ${model}`),
  });
  if (isOtherCarrier) {
    schemaObj.fields.otherCarrier = Yup.string()
      .trim()
      .required(`${pleaseEnter} ${otherCarrier}`);
  }
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, minimumDeductibles)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${pleaseEnter} ${otherDeductibles}`)
      .test('onlydigits', validOtherDeductibles, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};

export const editcommercialSchema = isOtherDeductibles => {
  const schemaObj = Yup.object({
    otherCarrier: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} ${deductibles}`),
    otherDeductibles: Yup.string().trim().nullable(),
  });
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.string()
      .trim()
      .matches(/^[1-9]\d*(\.\d+)?$/, validOtherDeductibles)
      .matches(/^\d*(\.\d{0,2})?$/, 'Only 2 digits are required after decimal')
      .required(`${pleaseEnter} ${otherDeductibles}`);
  }
  return schemaObj;
};

export const addCommercialsSchema = (isOtherDeductibles, isOtherCarrier) => {
  const schemaObj = Yup.object({
    carrier: Yup.object().nullable().required(`${pleaseSelect} ${carrierText}`),
    otherCarrier: Yup.string().trim().nullable(),
    deductibles: Yup.object()
      .nullable()
      .required(`${pleaseSelect} ${deductibles}`),
    otherDeductibles: Yup.string().trim().nullable(),
    make: Yup.string().required(`${pleaseEnter} ${maker}`),
    model: Yup.string().required(`${pleaseEnter} ${model}`),
  });
  if (isOtherCarrier) {
    schemaObj.fields.otherCarrier = Yup.string()
      .trim()
      .required(`${pleaseEnter} ${otherCarrier}`);
  }
  if (isOtherDeductibles) {
    schemaObj.fields.otherDeductibles = Yup.number()
      .min(100, minimumDeductibles)
      .test(
        'type',
        'Other Deductibles must be a number',
        value => !isNaN(value),
      )
      .typeError('Other Deductibles must be a number')
      .required(`${pleaseEnter} ${otherDeductibles}`)
      .test('onlydigits', validOtherDeductibles, value =>
        /^[1-9]\d*(\.\d+)?$/.test(value),
      )
      .test('twodecimals', 'Only 2 digits are required after decimal', value =>
        /^\d*(\.\d{0,2})?$/.test(value),
      );
  }
  return schemaObj;
};

export const profileScehma = Yup.object({
  firstName: Yup.string().required(firstName),
  lastName: Yup.string().required(lastName),
  email: Yup.string().email().required(emailv2),
  phone: Yup.string().required(phone),
  address: Yup.string().required(address),
  city: Yup.string().required(city),
  state: Yup.string().required(state),
  zip: Yup.string().required(zip),
  // birthDate: Yup.string().required(dob),
});

export const changePasswordScehma = Yup.object({
  currentPassword: Yup.string().required(password),
  password: Yup.string().min(6).required(newPassword),
  confirmPassword: Yup.string()
    .min(6, 'Confirm password must be at least 6 characters')
    .required(confirmPassRequires)
    .oneOf([Yup.ref('password'), null], confirm),
});

export const claimTypeScehma = Yup.object({
  dateLoss: Yup.string().required(`${pleaseEnter} ${dateLoss}`),
  dateField: Yup.string().required(`${pleaseEnter} ${dateField}`),
  // notes: Yup.string().required(`${pleaseEnter} ${notes}`),
  driverLicense: Yup.string().required(driverLicense),
  registrationDoc: Yup.string().required(registerationDoc),
  insuranceDeclaration: Yup.string().required(insuranceError),
  claimInfo: Yup.string().required(calimInfoError),
  incidentReport: Yup.string().required(incidentReportError),
});
export const addClaimWithoutAutoScheme = Yup.object({
  dateLoss: Yup.string().required(`${pleaseEnter} ${dateLoss}`),
  dateField: Yup.string().required(`${pleaseEnter} ${dateField}`),
  // notes: Yup.string().required(`${pleaseEnter} ${notes}`),
  registrationDoc: Yup.string().required(registerationDoc),
  insuranceDeclaration: Yup.string().required(insuranceError),
  claimInfo: Yup.string().required(calimInfoError),
  incidentReport: Yup.string().required(incidentReportError),
});
