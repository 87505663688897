import React, {useState, useEffect} from 'react';
import './styles.scss';
import {allCommonText} from '../../../../common/constants';
import {colors, images} from '../../../../common';
import {planCategory} from '../../policy-and-claims';
import {getSubscriptionById} from '../../../../utils/rest-services';
import {CircularProgress} from '@mui/material';
import {useNavigate} from 'react-router';
import {path} from '../../../../common/routesNames';
import {InvoiceAndSubscriptionDetails} from '../../../../components';

function SubscriptionDetails() {
  const [selectedtab, setSelectedtab] = useState(1);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [refundDetailPopup, setRefundDetailPopup] = useState(false);
  const [selectedRefundItem, setSelectedRefundItem] = useState(0);
  const MONTHLY_SUBSCRIPTION = 'monthly';
  const navigate = useNavigate();

  // Location
  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };

  let query = getParamsFromUrl();

  const subscriptionId = query.get('id');
  // UseEffect
  useEffect(() => {
    fetchSubscriptionDetails(subscriptionId);
  }, []);

  // API
  const fetchSubscriptionDetails = async () => {
    setLoader(true);
    let response = await getSubscriptionById(subscriptionId);

    if (response && response.data.success) {
      const {
        data: {content},
      } = response;
      setSubscriptionDetails(content);
      setLoader(false);
    }
  };

  const SubscriptionDetailsContent = ({title, Value, valueAmount}) => {
    return (
      <div className="sub-detail-content">
        <span className="sub-detail-content-title">{title}</span>
        <span className={valueAmount ? 'boldColor' : ''}>{Value}</span>
      </div>
    );
  };

  const planSelectorIcon = id => {
    switch (id) {
      case allCommonText.serviceTypeEnums.vehicle:
        return images.vehicleV;
      case allCommonText.serviceTypeEnums.home:
        return images.homeV;
      case allCommonText.serviceTypeEnums.device:
        return images.devicesV;
      case allCommonText.serviceTypeEnums.commercial:
        return images.commercialV;
      case allCommonText.serviceTypeEnums.disaster:
        return images.disasterIcon;
    }
  };

  const InvoiceItem = ({
    name,
    deductible,
    cost,
    serviceTypeId,
    make,
    model,
    brand,
    address,
  }) => {
    return (
      <div className="invoice-item-view">
        <div className="plan-icon">
          <img src={planSelectorIcon(serviceTypeId)} alt="" />
        </div>
        <div className="invoice-detail-card">
          <div className="primary-card-container-invoice ">
            <span>{name}</span>
            {make && <span>{`Make: ${make}`} </span>}
            {brand && <span>{`Brand: ${brand}`} </span>}
            {model && <span>{`Model: ${model}`} </span>}
            {address && <span>{`Address: ${address}`} </span>}
          </div>
          <div className="secondary-card-container-invoice">
            <span>
              {`${allCommonText.popupEnums.subscriptionPlanHeadings.deductibles}: ${deductible}`}
            </span>
            <div>
              <span> {`${allCommonText.labels.cost} ${cost}`} </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SubscriptionOrRefundItems = ({selectedTab, onRefundItemClicks}) => {
    if (selectedTab == 1) {
      return subscriptionDetails?.items?.length === 0 ? (
        <div>
          <span className="no-subscription-item">
            {allCommonText.noSubsItems}
          </span>
        </div>
      ) : (
        <>
          <div className="invoice-item-details">
            {subscriptionDetails?.items?.map(i => (
              <InvoiceItem
                key={i}
                make={i?.make}
                model={i?.model}
                brand={i?.brand}
                serviceTypeId={i.serviceTypeId}
                address={i?.address}
                cost={`$${parseFloat(i.cost).toFixed(2)}`}
                deductible={`$${parseFloat(i.deductibleAmount).toFixed(2)}`}
                name={i.providerName}
              />
            ))}
          </div>
        </>
      );
    } else {
      return subscriptionDetails?.refunds?.lenght == 0 ||
        !subscriptionDetails?.refunds ? (
        <div>
          <span className="no-subscription-item">
            {allCommonText.noRefundsItems}
          </span>
        </div>
      ) : (
        <div className="refunded-items-container">
          <div className="refund-title ">
            <span>{allCommonText.heading.refunds.Id}</span>
            <span>{allCommonText.heading.refunds.Date}</span>
            <span>{allCommonText.heading.refunds.Type}</span>
            <span>{allCommonText.heading.refunds.Amount}</span>
          </div>
          <div
            className={
              subscriptionDetails?.refunds?.length > 1
                ? 'refund-container'
                : 'refund-container-no-border'
            }>
            {subscriptionDetails?.refunds?.map(
              ({refundId, refundDate, refundType, refundAmount}) => (
                <div
                  key={refundId}
                  onClick={() => {
                    onRefundItemClicks(refundId);
                  }}>
                  <span>{refundId}</span>
                  <span>{refundDate.split(' ')[0]}</span>
                  <span>{refundType}</span>
                  <span>{`$${parseFloat(refundAmount).toFixed(2)}`}</span>
                </div>
              ),
            )}
          </div>
        </div>
      );
    }
  };
  return (
    <div className="main-subscription-container sub-detailt-pad">
      {loader ? (
        <div className="loader-container">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="sub-detail-header">
            <div className="sub-detail-titles">
              <div className="">
                <img
                  onClick={() => {
                    navigate(path.subscriptions);
                  }}
                  src={images.redirectIcon}
                  alt=""
                />
              </div>
              <div className="">
                <h3>{allCommonText.profile.subscriptionDetails}</h3>
              </div>
            </div>
            <div className="sub-detail-badges">
              <span
                className={`${planCategory(
                  subscriptionDetails?.isActive
                    ? 'Active'
                    : allCommonText.planCategory.annual,
                )} subscription-type-top-badge`}>
                {subscriptionDetails?.isActive ? 'Active' : 'Cancelled'}
              </span>
              <span
                className={`${planCategory(
                  subscriptionDetails?.subscriptionType,
                )} `}>
                {subscriptionDetails?.subscriptionType}
              </span>
            </div>
          </div>
          <div className="sub-detail-content-box">
            <SubscriptionDetailsContent
              title={'Subscription Id'}
              Value={`${subscriptionDetails?.viewSubscriptionId}`}
            />
            <SubscriptionDetailsContent
              title={'Amount'}
              Value={`$${parseFloat(
                subscriptionDetails?.subscriptionTotal,
              ).toFixed(2)}`}
              valueAmount={true}
            />
          </div>
          <div className="sub-detail-content-box">
            <SubscriptionDetailsContent
              title={'Created Date'}
              Value={`${subscriptionDetails?.createdDate?.replace(/\//g, '-')}`}
            />
            <SubscriptionDetailsContent
              title={
                subscriptionDetails?.couponDiscount
                  ? 'Coupon Discount'
                  : 'Discount Percent'
              }
              Value={
                subscriptionDetails?.couponDiscount
                  ? `$${parseFloat(subscriptionDetails?.couponDiscount).toFixed(
                      2,
                    )}`
                  : subscriptionDetails?.subscriptionType?.toLowerCase() ===
                    MONTHLY_SUBSCRIPTION
                  ? allCommonText.noDiscount
                  : `${parseFloat(subscriptionDetails?.discountPercent).toFixed(
                      2,
                    )}%`
              }
              valueAmount={true}
            />
          </div>
          <div className="sub-detail-content-box">
            <SubscriptionDetailsContent
              title={'Ended Date'}
              Value={
                subscriptionDetails?.endedDate != ''
                  ? subscriptionDetails.endedDate
                  : allCommonText.NotAvailabale
              }
            />
            <SubscriptionDetailsContent
              title={'Billing Card'}
              Value={
                <>
                  <i
                    className={`subscription-card-type subscription-item-card fa-brands fa-cc-${subscriptionDetails?.cardType}`}
                    style={{color: colors.primary}}></i>
                  <span>{`${'**** **** ****'} ${
                    subscriptionDetails?.cardNumber
                  }`}</span>
                </>
              }
            />
          </div>
          <div className="sub-detail-tabs">
            <div
              onClick={() => setSelectedtab(1)}
              className={
                selectedtab === 1
                  ? 'subscrition-item-tab sub-selected-tab'
                  : 'subscrition-item-tab'
              }>
              {allCommonText.subscriptionItem}
            </div>
            <div
              onClick={() => setSelectedtab(2)}
              className={
                selectedtab === 2
                  ? 'subscrition-item-tab sub-selected-tab'
                  : 'subscrition-item-tab'
              }>
              {allCommonText.refund}
            </div>
          </div>
          <div className="invoice-container-v2">
            <SubscriptionOrRefundItems
              onRefundItemClicks={i => {
                setSelectedRefundItem(i);
                setRefundDetailPopup(true);
              }}
              selectedTab={selectedtab}
            />
          </div>
        </>
      )}
      {refundDetailPopup && (
        <InvoiceAndSubscriptionDetails
          show={refundDetailPopup}
          refundId={selectedRefundItem}
          handelClose={() => setRefundDetailPopup(false)}
        />
      )}
    </div>
  );
}

export default SubscriptionDetails;
