import React, {useRef, useState, useEffect} from 'react';
import {useNavigate} from 'react-router';
import {Grid} from '@mui/material';
import {Formik} from 'formik';
import {path} from '../../common/routesNames';
import {addItemToCart, verfyZipCode} from '../../utils/rest-services';
import {allCommonText, plans, signupText} from '../../common/constants';
import {addHomeSchema, addHomeSchemaWithDisaster} from '../../common/schemas';
import {
  TextInputField,
  CriteriaDropdown,
  GenericButton,
  Loader,
} from '../index';
import Autocomplete from 'react-google-autocomplete';
import '../../App.scss';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {images} from '../../common';
import {ViewCartButton} from './../view-cart-button/index';
import {HomeTermsAndConditions} from '../home-terms-and-conditions';

export const AddHomeInputs = ({
  carrierItem,
  deductibleItem,
  setPopUpResponse,
  setSowModal,
  selectedCartItems,
  loadingData,
  onItemAddedToCart,
}) => {
  useEffect(() => {
    getSelectedItems(selectedCartItems);
  }, []);

  const [selectedOption, setSelectedOption] = useState({
    carrier: null,
    deductible: null,
    otherDeductibles: '',
    address: null,
  });
  const [isOtherDeductibles, setIsOtherDeductibles] = useState(false);
  const [isOtherCarrier, setIsOtherCarrier] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isDisasterCheckMatch, setIsDisasterCheckMatch] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [termsandConditionPopup, setTermsandConditionPopup] = useState(false);
  const [zipLoader, setZipLoader] = useState(false);
  const [zipErrorMsg, setZipErrorMsg] = useState('');
  const [disasterData, setDisasterData] = useState(false);
  const {hiddenKeys, serviceTypeEnums, error} = allCommonText;
  const carrierRef = useRef();
  const deductiblesRef = useRef();
  let navigate = useNavigate();

  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }

  const numberWithCommas = x => {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
    return x;
  };

  const getZipCodeInformation = async (zipCode, emptydisasterDeductibles) => {
    setZipLoader(true);
    let response = await verfyZipCode(zipCode);
    setZipLoader(false);

    if (response && response.success) {
      emptydisasterDeductibles();
      setDisasterData(response.content);
      setZipErrorMsg('');
    } else {
      setDisasterData(null);
      setZipErrorMsg(response.description);
      setTermsAndConditions(true);
    }
  };

  const getSelectedItems = selectedCartItems => {
    const isObjectEmpty = isEmptyObject(selectedCartItems);
    if (!isObjectEmpty) {
      // check weather it is in edit mode or add mode
      const {
        serviceProviderName,
        serviceProviderId,
        deductibleAmount,
        address,
      } = selectedCartItems || {};
      let deductible = `$${numberWithCommas(deductibleAmount)}.00`;
      let index = deductibleItem.findIndex(item => item.value == deductible);
      const carrierObj = {
        value: serviceProviderName,
        label: serviceProviderName,
        id: serviceProviderId,
      };
      const deductibleObj = {
        value: deductible,
        label: deductible,
        id: 0,
      };
      const otherDeductibleObj = {
        id: -1,
        value: allCommonText.otherDeductibles,
        label: allCommonText.otherDeductibles,
      };

      if (index === -1) {
        setIsOtherDeductibles(true);
        setSelectedOption({
          carrier: carrierObj,
          otherDeductibles: deductibleAmount.toString(),
          address: address, // here is difference
          deductible: otherDeductibleObj,
        });
      } else {
        setIsOtherDeductibles(false);
        setSelectedOption({
          carrier: carrierObj,
          deductible: deductibleObj, // here is difference
          address: address, // here is difference
        });
      }
      setIsEditable(true); // make component ready for edit or add
    } else {
      setSelectedOption({
        carrier: null,
        deductible: null,
        otherDeductibles: '',
        address: null,
      });
      setIsEditable(false);
    }
  };

  const AddHomeToCart = async (values, actions) => {
    let AddToCartPayload;
    if (isEditable) {
      AddToCartPayload = {
        serviceTypeId: serviceTypeEnums.home.toString(),
        serviceProviderId: selectedCartItems.serviceProviderId,
        otherServiceProvider: null,
        deductibleAmount:
          values.deductibles.id === hiddenKeys.notAvailableFieldId
            ? values.otherDeductibles
            : values.deductibles.id,
        address: selectedCartItems.address,
        cartItemId: selectedCartItems.cartItemId,
      };
    } else {
      AddToCartPayload = {
        serviceTypeId: serviceTypeEnums.home.toString(),
        serviceProviderId: values.carrier.id,
        otherServiceProvider: values.otherCarrier,
        deductibleAmount:
          values.deductibles.id === hiddenKeys.notAvailableFieldId
            ? values.otherDeductibles
            : values.deductibles.id,
        address: `${values.address},${values.city},${values.state}`,
        cartItemId: 0,
        zipCode: values.zip,
        isDisaster: isDisasterCheckMatch && disasterData != null,
        disasterDeductible: values?.disasterDeductibles?.id || 0,
      };
    }

    let result = await addItemToCart(AddToCartPayload);
    if (result && result.success) {
      toast(
        <div className="cart-icon-toaster">
          <img src={images.cartSmall} />
          <span className="toast-text">{result.description}</span>
        </div>,
      );
      onItemAddedToCart();
      if (isEditable) {
        navigate({
          pathname: path.plans,
        });
      }
    } else {
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        title: error,
        description: result.description,
      });
      setSowModal(true);
    }
    actions.setSubmitting(false);
  };

  const convertArrayToString = data => {
    const joined = data.join();
    let newAddress = joined.replace(/,/g, ' ');
    return newAddress;
  };

  const AddressSeprateHandler = (data, setFieldValue, values) => {
    let address = [];
    let city = [];
    let state = [];
    let postal = [];
    data.map(dataItem => {
      dataItem.types.map(type => {
        if (type === signupText.fieldTypeEnums.streetNumber) {
          address.push(dataItem.long_name);
        } else if (type === signupText.fieldTypeEnums.route) {
          address.push(dataItem.long_name);
        } else if (type === signupText.fieldTypeEnums.naibhour) {
          address.push(dataItem.long_name);
        } else if (type === signupText.fieldTypeEnums.locality) {
          city.push(dataItem.long_name);
        } else if (type === signupText.fieldTypeEnums.admin) {
          state.push(dataItem.long_name);
        } else if (type === signupText.fieldTypeEnums.code) {
          postal.push(dataItem.long_name);
        }
      });
    });
    //convert Address array to string
    setFieldValue(allCommonText.fieldsValue.address, values);
    setFieldValue(allCommonText.fieldsValue.city, convertArrayToString(city));
    setFieldValue(allCommonText.fieldsValue.state, convertArrayToString(state));
    setFieldValue(allCommonText.fieldsValue.zip, convertArrayToString(postal));
  };

  const getRiskClassName = riskLevel => {
    if (riskLevel === 'high') {
      return 'high-risk-class';
    } else if (riskLevel === 'low') {
      return 'low-risk-class';
    } else if (riskLevel === 'medium') {
      return 'medium-risk-class';
    } else {
      return 'non-risk-class';
    }
  };
  const getRiskBadgs = riskLevel => {
    if (riskLevel === 'high') {
      return images.highRiskBadge;
    } else if (riskLevel === 'low') {
      return images.lowRiskBadge;
    } else {
      return images.mediumRiskBadge;
    }
  };

  return (
    <Formik
      initialValues={{
        carrier: null,
        deductibles: selectedOption.deductible || null,
        disasterDeductibles: null,
        otherDeductibles: selectedOption.otherDeductibles || '',
        otherCarrier: '',
        address: '',
        city: '',
        state: '',
        zip: '',
      }}
      onSubmit={(values, formikActions) => {
        AddHomeToCart(values, formikActions);
      }}
      validationSchema={
        isDisasterCheckMatch && disasterData != null
          ? addHomeSchemaWithDisaster(isOtherDeductibles, isOtherCarrier)
          : addHomeSchema(isOtherDeductibles, isOtherCarrier)
      }
      enableReinitialize={true}>
      {({
        handleChange,
        handleSubmit,
        touched,
        errors,
        values,
        setFieldValue,
        isSubmitting,
        handleBlur,
      }) => (
        <>
          {loadingData ? (
            <Loader customContainerClass="loader-container-plan" />
          ) : (
            <div className="selectCriteria">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <label htmlFor="" className="plan-label">
                    {plans.carrier_}
                  </label>
                  <CriteriaDropdown
                    options={carrierItem}
                    isDisable={isEditable}
                    name={'carrier'}
                    selectedOption={selectedOption.carrier || values.carrier}
                    error={touched.carrier && errors.carrier}
                    dropdowntitle={plans.selectCriteria}
                    onChangeOption={e => {
                      isEditable && setSelectedOption({carrier: e});
                      setFieldValue('carrier', e);
                      handleChange('carrier');
                      if (e.id === hiddenKeys.notAvailableFieldId) {
                        setIsOtherCarrier(true);
                        setTimeout(() => {
                          carrierRef?.current?.focus();
                        }, 500);
                      } else {
                        setIsOtherCarrier(false);
                      }
                    }}
                  />
                </Grid>
                {values?.carrier?.id === hiddenKeys.notAvailableFieldId && (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <label htmlFor="" className="plan-label">
                      {plans.otherCarrier_}
                    </label>
                    <TextInputField
                      value={values.otherCarrier}
                      disable={isEditable}
                      setValue={e => {
                        setFieldValue('otherCarrier', e.target.value);
                        handleChange('otherCarrier');
                      }}
                      placeHolder={plans.otherCarrierName}
                      name={allCommonText.otherCarrier}
                      error={touched.otherCarrier && errors.otherCarrier}
                      reff={carrierRef}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <label htmlFor="" className="plan-label">
                    {plans.deductibles_}
                  </label>
                  <CriteriaDropdown
                    options={deductibleItem}
                    error={touched.deductibles && errors.deductibles}
                    dropdowntitle={plans.selectDeductible}
                    name={allCommonText.deductibles}
                    selectedOption={
                      selectedOption.deductible || values.deductibles
                    }
                    onChangeOption={e => {
                      isEditable &&
                        setSelectedOption(other => ({
                          ...other,
                          deductible: e,
                        }));
                      handleChange('deductibles');
                      setFieldValue('deductibles', e);
                      if (e.id === hiddenKeys.notAvailableFieldId) {
                        setIsOtherDeductibles(true);
                        setTimeout(() => {
                          deductiblesRef?.current?.focus();
                        }, 500);
                      } else {
                        setIsOtherDeductibles(false);
                      }
                    }}
                  />
                </Grid>
                {(values?.deductibles?.id === hiddenKeys.notAvailableFieldId ||
                  isOtherDeductibles) && (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <label htmlFor="" className="plan-label">
                      {plans.otherDeductibles_}
                    </label>
                    <TextInputField
                      value={
                        selectedOption.otherDeductibles ||
                        values.otherDeductibles
                      }
                      setValue={e => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // remove non-numeric characters
                        const restrictedValue = numericValue.substring(0, 7); // restrict to 10 digits

                        setFieldValue('otherDeductibles', restrictedValue);
                        handleChange('otherDeductibles');
                        isEditable &&
                          setSelectedOption(other => ({
                            ...other,
                            otherDeductibles: e.target.value,
                          }));
                      }}
                      placeHolder={plans.otherDeductibles}
                      name={allCommonText.otherDeductibles}
                      error={
                        touched.otherDeductibles && errors.otherDeductibles
                      }
                      reff={deductiblesRef}
                    />
                  </Grid>
                )}
                <Grid item xs={12} xl={12} lg={12} md={12}>
                  <label htmlFor="" className="plan-label">
                    {plans.location}
                  </label>
                  <Autocomplete
                    className="input-field-generic auto-complete-address yearContainer address-in-plan"
                    apiKey={allCommonText.ADDRESSKEY}
                    // apiKey={process.env.REACT_APP_ADDRESSKEY}
                    defaultValue={values.address}
                    onChange={handleChange('address')}
                    onBlur={handleBlur}
                    options={{
                      componentRestrictions: {country: 'us'},
                    }}
                    onPlaceSelected={data => {
                      AddressSeprateHandler(
                        data.address_components,
                        setFieldValue,
                        data.formatted_address,
                      );
                    }}
                  />
                  <div className="error-input-container">
                    {errors.address && touched.address ? (
                      <p className="form-error">{errors.address}</p>
                    ) : null}
                  </div>
                </Grid>

                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <label htmlFor="" className="plan-label">
                    {allCommonText.placeholders.city}
                  </label>
                  <TextInputField
                    error={touched.city && errors.city}
                    setValue={e => {
                      setFieldValue('city', e.target.value);
                      handleChange('city');
                    }}
                    value={values.city}
                    placeholder={allCommonText.placeholders.enterCity}
                  />
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <label htmlFor="" className="plan-label">
                    {allCommonText.placeholders.state}
                  </label>
                  <TextInputField
                    error={touched.state && errors.state}
                    setValue={e => {
                      setFieldValue('state', e.target.value);
                      handleChange('state');
                    }}
                    value={values.state}
                    placeholder={allCommonText.placeholders.enterState}
                  />
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <label htmlFor="" className="plan-label">
                    {allCommonText.placeholders.zipCode}
                  </label>
                  <TextInputField
                    error={touched.zip && errors.zip}
                    setValue={event => {
                      const inputValue = event.target.value;
                      if (/^\d{0,6}$/.test(inputValue)) {
                        setFieldValue('zip', inputValue);
                        handleChange('zip');
                        setIsDisasterCheckMatch(false);
                      }
                    }}
                    // setValue={e => {
                    //   setFieldValue('zip', e.target.value);
                    //   handleChange('zip');
                    //   setIsDisasterCheckMatch(false);
                    // }}
                    value={values.zip}
                    placeholder={allCommonText.placeholders.enterZipCode}
                  />
                </Grid>
              </Grid>
              <div class="add-coverage-box home-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  disabled={values.zip === ''}
                  id="flexCheckChecked"
                  checked={isDisasterCheckMatch}
                  onChange={d => {
                    if (isDisasterCheckMatch) {
                      setTermsAndConditions(false);
                    } else {
                      getZipCodeInformation(values.zip, () =>
                        setFieldValue('disasterDeductibles', null),
                      );
                    }
                    setIsDisasterCheckMatch(d.target.checked);
                  }}
                />
                <span class="add-coverage-box-label" for="flexCheckChecked">
                  {allCommonText.labels.addDisasterCoverage}
                </span>
              </div>
              {isDisasterCheckMatch &&
                (zipLoader ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  <div>
                    <div className="disaster-deduction-module border-deduction">
                      <div
                        className={`high-risk-container ${getRiskClassName(
                          disasterData?.zipDetails?.riskLevel,
                        )}`}>
                        <span>Note:</span>
                        {!disasterData ? (
                          <span>
                            {zipErrorMsg ||
                              `Your Zip code Doesnot exist in disaster Area.`}
                          </span>
                        ) : (
                          <>
                            <span>
                              {` You're in a ${disasterData?.zipDetails?.riskLevel}-Risk Area. So, your disaster
                          deductible amount will be ${disasterData?.zipDetails?.percentage}% for this plan.`}
                            </span>
                            <span className="risk-badge">
                              <img
                                src={getRiskBadgs(
                                  disasterData?.zipDetails?.riskLevel,
                                )}
                              />
                            </span>
                          </>
                        )}
                      </div>
                      {disasterData && (
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <CriteriaDropdown
                              options={disasterData?.disasterDeductibles}
                              error={
                                touched.disasterDeductibles &&
                                errors.disasterDeductibles
                              }
                              dropdowntitle={plans.selectDisasterDeductible}
                              name={plans.selectDisasterDeductible}
                              selectedOption={values.disasterDeductibles}
                              onChangeOption={e => {
                                handleChange('disasterDeductibles');
                                setFieldValue('disasterDeductibles', e);
                              }}
                            />
                          </Grid>
                          {values?.disasterDeductibles?.id && (
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                              <div className="disaster-dudctible-cost">
                                <span>{allCommonText.labels.cost_}</span>
                                <span>
                                  {` $${(
                                    values?.disasterDeductibles?.id *
                                    (disasterData?.zipDetails?.percentage / 100)
                                  ).toFixed(2)}`}
                                </span>
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      )}

                      {disasterData && (
                        <div className="disaster-deduction-module remove-padding">
                          <div class="add-coverage-box home-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                              checked={termsAndConditions}
                              onChange={d => {
                                if (termsAndConditions)
                                  setTermsAndConditions(false);
                                else
                                  setTermsandConditionPopup(d.target.checked);
                              }}
                            />
                            <span
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                if (termsAndConditions)
                                  setTermsAndConditions(false);
                                else
                                  setTermsandConditionPopup(
                                    !termsandConditionPopup,
                                  );
                              }}
                              className="reading-confirm-text">
                              <span
                                class="read-terms-text"
                                for="flexCheckChecked">
                                {allCommonText.labels.homeTermsAndConditions1}
                              </span>
                              <span class="terms-and-condition">
                                {allCommonText.labels.TAndC}
                              </span>
                              <span>
                                {allCommonText.labels.homeTermsAndConditions2}
                              </span>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    {termsandConditionPopup && (
                      <HomeTermsAndConditions
                        show={termsandConditionPopup}
                        onSelectTerms={() => {
                          setTermsandConditionPopup(false);
                          setTermsAndConditions(true);
                        }}
                        handleClose={() => {
                          setTermsandConditionPopup(false);
                          setTermsAndConditions(false);
                        }}
                      />
                    )}
                  </div>
                ))}
            </div>
          )}
          <div className="cartContainer mt-3">
            <GenericButton
              id="plan-cart"
              loading={isSubmitting}
              disable={isDisasterCheckMatch ? !termsAndConditions : false}
              buttonText={plans.AddToCart}
              onPress={handleSubmit}
            />
            <ViewCartButton />
          </div>
        </>
      )}
    </Formik>
  );
};
