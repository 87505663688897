export const header = {
  home: 'Home',
  blog: 'Blog',
  myAccount: 'My Account',
  contactUs: 'Contact Us',
  aboutUS: 'About Us +',
  affiliates: 'Affiliates',
  joinTheTeam: 'Join the Team +',
  deductiblePlans: 'Deductible Plans +',
  autoPlan: 'Auto Deductible Plan',
  homePlan: 'Home Deductible Plan',
  commercialPlan: 'Commercial Deductible Plan',
  phonePLan: 'Phone Deductible Plan',
  bcomeAffiliates: 'Affiliate',
  insuranceAgent: 'Insurance Agent',
  salesAmbassador: 'Sales Ambassador',
  agency: 'Agency',
  distributionChannel: 'Distribution Channel',
  testimonials: 'Testimonials',
  faqs: 'FAQs',
  tempValue: '$ 1,500',
  profile: 'Profile',
  logout: 'Logout',
};
export const footer = {
  footerSignUp: 'Say no to insurance deductibles.Sign up with No Duk Today!',
  newsLetter: 'Newsletter',
  subscribeNewsletter:
    'Subscribe our newsletter to get our latest update & news.',
  subscribe: 'Subscribe',
  officialInfo: ' Official Info:',
  officialInfo1: 'Office Address: 8 The Green Suite # 8234 Dover, DE 19901',
  phone: '1-855-NODUK99',
  mobile: '302-401-6588',
  openHours: 'Open Hours',
  workingDay: 'Mon – Sat: 8 am – 5 pm,',
  closedDay: 'Sunday: CLOSED',
  loadMore: 'Load More',
  nodukOfficial:
    'Eliminate Deductibles Forever with NO DUK- Auto - Home - Mobile - Commercial',
  eliminateDeductible:
    'Eliminate Deductibles Forever with NO DUK - Auto Home Mobile Commercial',
  followOnIsta: 'Follow on Instagram',
  rights: 'Copyright © Designed & Developed by JepNest LLC 2023',
  privacyPolicy: ' Privacy Plan ',
};
export const error = {errorBoundry: 'Something Went Wrong!'};

export const allCommonText = {
  noduk: 'No Duk',
  noInfoAvailabale: 'No Info Available',
  pendingInfoAvailabaleNote: 'Your Claim is in the pending state',
  noItemsAvailabale: 'No Items Available',
  noSubsItems: 'No Subscriotion Items Available',
  noInvoiceItems: 'No Invoice Items Available',
  noRefundsItems: 'No Refund Items Available',
  plansTitle: 'has plans that will not break the bank!',
  once: `once`,
  success: 'success',
  somethingWrong: 'Some Went Wrong!',
  ADDRESSKEY: 'AIzaSyDVIN3eonG7yBS2wqv3S6hEBGe3gcrt0AM',
  error: 'error',
  checkoutError: 'Error While Checkout!',
  ok: 'OK',
  cancel: 'Cancel',
  alert: 'Alert',
  sure: 'Are You Sure?',
  yes: 'YES',
  no: 'NO',
  icon: 'Icon',
  image: 'Image',
  update: 'Update',
  emptyCartTitle: 'Cart Empty?',
  thanks: 'Thank you',
  addNewPlans: '+ Add New Plan',
  cardSavedAsDefault: 'Card saved as default',
  couponNote: `Adding promo code will remove other plan discounts.`,
  emailVerifications: ' You email has been verified. Now you can access the',
  errorPageText: 'The page you are looking for is temporarily unavailable.',
  nodukPortal: 'NoDuk Portal',
  verificationNote:
    ' If you change this number, your signup phone number will be updated.',
  confirmataionMessage:
    'Are you sure you want to remove all the items in your cart?',
  otherDeductibles: 'Other Deductibles',
  refundDetails: 'Refund Details',
  termsCondition1: 'I have read and agree with the ',
  termsCondition2: 'Terms and Conditions.',
  upgradeTitle: 'Pricing Plans',

  NotAvailabale: 'N/A',
  subscriptionItem: 'Subscription Items',
  refund: 'Refunds',
  upgradeDetails:
    ' Familiarize yourself with the payment plans below see for yourself that the basic service for your business is not as expensive as it might seem',

  fieldsValue: {
    address: 'address',
    city: 'city',
    state: 'state',
    zip: 'zip',
  },
  forever: ` (Off forever)`,
  onlyForFirstInvoice: ` (Off on first invoice only)`,
  planTypes: {
    monthly: 'monthly',
    biannual: 'biannual',
    biannually: 'biannually',
    annual: 'annual',
    month: 'month',
  },
  discounPerMonth: {
    monthly: 'Monthly Plan',
    biannual: 'Biannual Plan',
    annual: 'Annual Plan',
  },
  viewCardType: {
    invoice: 1,
    subscription: 2,
    policyAndClaim: 3,
    detailButtonOnly: 1,
    refund: 4,
  },
  successfullPayment: {
    title: 'Thank you for your payment!',
    paymentReceived: ' Your payment has been received successfully!',
    description:
      'We are excited to provide you with coverage for your protection. If you need anything at all, please contact us at ',
  },
  buttonTexts: {
    add: 'Add',
    edit: 'Edit',
    payNow: 'Pay Now',
    continue: 'Continue',
    saveAndcontinue: 'Save and Continue',
    addCard: '+ Add New Card',
    addCard_: 'Add Card',
    change: 'Change',
    search: 'Search',
    viewDetails: 'View Details',
    updatePayment: 'Update Payment Card',
    cancelSubscription: 'Cancel Subscription',
    upgradeSubscription: 'Upgrade Subscription',
    updateCard: 'Update Card',
    close: 'Close',
    sendCode: 'Send Code',
    sendNewCode: 'Send a new code',
    contactUs: 'Contact Us',
    couponCode: `Coupon code`,
    enterCode: 'Enter Code',
    apply: `Apply`,
    discountedPrice: `Discounted price`,
  },
  errorMessage: {
    cardName: 'Please enter card holder name',
    cardNumber: 'Please enter a card number',
    cardExpiry: 'Please enter a Expiry Date',
    cardCvc: 'Please enter a CVC',
    invalidEmail: 'Invalid Email',
    driverLicense: `Driver License  is required`,
    registerationDoc: `Registration Doc is required`,
    insuranceError: `Insurance Declaration  is required`,
    calimInfoError: `Claim info is required`,
    incidentReportError: `Incident Report is required`,
    minimumDeductibles: 'Minimun deductible should be $100',
    year: {
      number: 'Year must be a number',
      integer: 'Year must be an integer',
      max: 'Year must be less than or equal to 2023',
      min: 'Year must be greater than or equal to 1900',
      required: 'Year is required',
    },
  },
  placeholders: {
    firstName: 'First Name*',
    lastName: 'Last Name*',
    email: 'Email*',
    dob: 'Date of Birth',
    phone: 'Phone Number*',
    city: 'City*',
    enterCity: 'Enter City',
    state: 'State*',
    enterState: 'Enter State',
    address: 'Address*',
    zipCode: 'Zip Code*',
    enterZipCode: 'Enter Zip Code',
    password: 'Password',
    newPassword: 'New Password',
    enterNewPassword: 'Enter New Password',
    confirmPassword: 'Confirm Password',
    confirmNewPassword: 'Confirm New Password',
    enterHolderName: 'Enter Card Holder Name',
    enterPolicyId: 'Enter Plan Id',
    typeReason: 'Type Reason',
    claimNumber: 'Claim Id',
    policyID: 'Plan Id',
    policyIDNumber: 'Plan Id',
    invoiceId: 'Invoice Id',
    invoiceIdNumber: 'Enter Invoice Id',
    subscriptionId: 'Subscription Id',
    refundId: 'Refund Id',
    refundIdNumber: 'Refund Id',
    subscriptionType: 'Subscription Type',
    currentPassword: `Current Password`,
    enterCurrentPassword: `Enter Current Password`,

    emailAddress: 'Your email address',
  },
  labels: {
    policyID: 'Plan Id',
    policyType: 'Plan Type',
    claimPolicy: 'Claim Plan Id',
    refundID: 'Refund Id',
    claimNumber: 'Claim Id',
    enter: 'Enter',
    viewByStatus: 'Status',
    cardNumber: 'Card Number*',
    currentPassword: `Current Password`,
    sixDigitCode: `A 6-digit OTP will be sent to your new mobile number`,
    newPassword: `New Password`,
    confirmPassword: `Confirm Password`,
    expiry: 'Expiration*',
    cvv: 'CVC*',
    name: 'Card Holder Name*',
    saveCard: 'Save Card',
    addDisasterCoverage: 'Add Disaster Coverage',
    iAcceptTerms: 'I accept the terms and conditions',
    homeTermsAndConditions1: 'I have read and agree on',
    homeTermsAndConditions2: 'about disaster situation.',
    TAndC: 'Terms and Conditions',
    cost_: 'Cost:',

    renews: {
      monthly: 'Renews Every Month',
      biannual: 'Renews Automatically Biannually',
      yearly: 'Renews Automatically Every Year',
    },

    invoiceId: 'Invoice Id',
    cost: 'Cost:',
    amount: 'Amount',
    card: 'Card',
    cardLastDigits: 'Card Last 4',
    billingCard: 'Billing Card',
    subscriptionId: 'Subscription Id',
    TotalAmount: 'Total Amount',
    previousBalance: 'Previous Balance',
    currentBalance: 'Current Balance',
    subscriptionAmount: 'Subscription Amount:',
    subscriptionType: 'Subscription Type',
    refundType: 'Refund Type',
    noInvoice: 'No Invoice Available!',
    noRefund: 'No Refund Available!',
    noBillingCardsFounds: 'No Billing Cards Available!',
    noSubscription: 'No Subscriptions Found!',
    noNotification: 'No Notifications Available!',
    noPolicy: 'No Plans Available!',
    noClaim: 'No Claims Available!',
    cantAccess: 'Can’t access your account?',
    dueDate: 'Due Date',
    refundDate: 'Refund Date',
    totalAmount: 'Amount',
    paidDate: 'Paid Date',
    subTotal: 'Sub Total',
    discount: 'Discount',

    createdDate: 'Created Date',
    invoiceStatus: {
      paid: 'Paid',
      upcoming: 'Upcoming',
      failed: 'Failed',
      all: 'All',
      open: 'Open',
      draft: 'Draft',
      settled: 'Settled',
    },
    subscriptionStatus: {
      active: 'Active',
      cancel: 'Cancelled',
      all: 'All',
    },
    refundStatus: {
      succeed: 'Succeeded',
      failed: 'Failed',
      pending: 'Pending',
      all: 'All',
    },
    refundTypes: {
      partial: 'Partial',
      full: 'Full',
      all: 'All',
    },
  },
  heading: {
    sureToDeleteCards: 'Delete Card ?',
    invoiceItems: 'Invoice Items',
    refundItems: 'Refunded Items',
    subscriptionItems: 'Subscription Items',
    paymentAndPaymentItems: 'Invoice Items',
    refundsItems: 'Refund Items',
    attachments: 'Attachments',
    notes: 'Notes',
    addNewCard: 'New Credit/Debit Card.',
    newCardAdd:'Add New Card',
    searchPolicy: 'Search Plan by',
    searchClaim: 'Search Claim by',
    searchInvoice: 'Search Invoice by',
    searchRefund: 'Search Refund by',
    searchSubscription: 'Search Subscription by',
    searchCard: 'Search Cards by',
    phoneVerficationDescription: 'Enter the code sent to',
    editPhoneVerficationDescription:
      'You need to verify your phone number to update.',
    phoneVerficationHeading: 'Phone number verification required',
    subscriptionInfo: 'Subscription Information',
    subscriptionDetails: 'Subscription Details',
    deleteCards: 'Are you sure you want to delete this card?',
    refunds: {
      Id: 'Refund Id',
      Date: 'Refund Date',
      Type: 'Refund Type',
      Amount: 'Refund Amount',
    },
    discountsPlan: {
      monthly: 'No Discount',
      annualy: '10%',
      biannual: '5%',
    },
  },
  urls: {
    home: 'https://noduk.com/',
    blog: 'https://noduk.com/blog/',
    info: 'info@noduk.com',
  },
  dateLoss: 'Date Loss',
  dateField: 'Date Filed',
  notes: 'Notes',
  upload: 'Please upload the',
  license: 'Licence',
  registration: 'Registration Document',
  insurance: 'Insurance Declaration Pages',
  claimInfo: 'Upload Claim Info',
  carrier: 'carrier',
  maker: 'maker',
  modal: 'model',
  year: 'year',
  address: 'address',
  otherCarrier: 'Other Carrier',
  brand: 'brand',
  model: 'model',
  validOtherDeductibles: 'Other deductibles must contain valid amount',
  deductibles: 'deductibles',
  pleaseSelect: 'Please select',
  file: 'the file to upload',
  pleaseEnter: 'Please enter the',
  toalDeductibles: 'Total Deductibles',
  discount: 'Discount',
  currentInvoice: `Current Invoice`,
  platformFee: `Platform Fee`,
  totalCurrentInv: `Total Current Invoice`,
  futureInvoice: `Future Invoice`,
  noDiscount: `No Discount`,
  toalRecurring: 'Total Recurring',
  note: 'Note:',
  billingCardLimit: 'You can not add more than 5 payment cards',
  learnMore: 'Learn More',
  selectPayment: 'Select Payment Card',
  linkedSubscription: 'Linked subscriptions',
  invoiceDetails: 'Invoice Details',
  nodukInfo:
    'No Duk is not providing insurance services it’s a deductibles app. To know more about No Duk',
  hiddenKeys: {
    notAvailableFieldId: -1,
    plansId: {
      monthly: 1,
      biAnnual: 2,
      annual: 3,
    },
  },
  serviceTypeEnums: {
    vehicle: 1,
    home: 2,
    device: 3,
    commercial: 4,
    disaster: 5,
  },
  claimStatusId: {
    pending: 1,
    settled: 2,
    cancelled: 3,
    rejected: 4,
  },
  popupEnums: {
    success: 1,
    error: 2,
    alert: 3,
    delete: 4,
    sure: 5,

    plansTitle: "has plans that won't break the bank!",
    noduk: 'NO DUK ',
    subscriptionSummary: 'Subscription Summary',
    claimSuccess: 'New Claim Addedd Successfully!',
    plansType: {
      monthly: 'Monthly Plan',
      biAnnual: 'BIAnnual Plan',
      annual: 'Annual Plan',
    },
    subscriptionPlanHeadings: {
      item: 'Item',
      deductibles: 'Deductibles',
      recurring: 'Recurring',
    },
  },
  couponMessage: {
    amountLessThanMinAmount: `Unfortunately, this coupon is not applicable to the current amount being processed.`,
    onlyMonthlyCoupon: `Unfortunately, this coupon is only applicable to Monthly Plan`,
    COUPON_GREATER_THAN:
      'Unfortunately,this coupon is not applicable. Minimum checkout amount should be greater than 1$.',
  },
  dashboard: {
    dashboard: 'Dashboard',
    previousCoverages: 'Your Previous Coverages',
    openClaim: 'Open Claims',
    closedClaim: 'Closed Claims',
  },
  profile: {
    editProfile: 'Profile',
    policyClaims: 'Plans & Claims',
    invoicesPayments: 'Invoices & Payments',
    invoicesPaymentsDetail: 'Invoices & Payments Details',
    changePassword: 'Change Password',
    subscriptions: 'Subscriptions',
    subscriptionDetails: 'Subscription Details',
    refundDetails: 'Refund Details',
    refund: 'Refunds',
    billingCards: 'Billing Cards',
    account: 'Account',
    save: 'Save',

    notifications: 'Notifications',
    feedBack: 'Feedback',
    deleteAccount: 'Delete Account',
  },
  policyClaims: {
    policy: 'Plans',
    brandName: 'Brand Name',
    claim: 'Claims',
    address: 'Address',
    cancelPolicy: 'Cancel Plan',
    cancelSubscription: 'Cancel Subscription',
    addClaim: 'Add Claim',
    cost: 'Cost: ',
    monthlyCost: 'Monthly Cost: ',
    policyID: 'Plan Id',
    planCost: 'Plan Cost',
    claimID: 'Claim Plan Id',
    make: 'Make',
    model: 'Model',
    deductibles: 'Deductibles',
    amount: 'Amount: ',
    claimDate: 'Claim plan Id',
    dateLoss: 'Date Loss',
    fileDate: 'Date File ',
    settlledDate: 'Date Settled ',
    rejectedDate: 'Date Rejected ',
    cancelledDate: 'Date Cancelled ',
    pageSize: 10,
    noLinkedSubscription: 'There are no subscriptions linked with this card.',
    cantDelCard:
      'This card is linked to some subscriptions. To delete the card, please detach the card from the linked subscriptions.',
    cancelPolicyPopup:
      'You can’t cancel your plan before 60 days. Once your time is complete you can cancel anytime.',
    cannotCancelPolicy: "Can't cancel plan!",
    cannotCancelSubscription: "Can't cancel subscription!",
    cancelSubscriptionmessage:
      'You can’t cancel your subscription before 60 days. Once your time is complete you can cancel anytime.',
    claimCannotAdd: 'Cannot add claim.',
    cancelPolicyMessage: 'Are you sure you want to cancel your plan?',
    cancelsubscriptionMessage:
      'Are you sure you want to cancel your subscription?',
    noteCancelPolicy: 'Note: Monthly policies are not refunded.',
    noteCancelSubsciption: 'Note: Monthly subscriptions are not refunded.',
    claimType: 'Claim Type',
    claimAmount: 'Claim Amount',
    provider: 'Carrier Name',
    dateField: 'Date Filed',
    notes: 'Notes',
    attachments: 'Attachments',
    upload: 'Upload Title',
    note: 'Note: Please upload driver license front and back picture',
    annual: 'Annual',
    monthly: 'Monthly',
    month: 'month',
    all: 'All',
    pending: 'Pending',
    cancelled: 'Cancelled',
    rejected: 'Rejected',
    settled: 'Settled',
    biAnnual: 'Biannual',
    notAvailable: 'N/A',
    recordNotFound: 'No Record Found!',
    auto: 'Auto',
    home: 'Home',
    mobile: 'Device',
    commercial: 'Commercial',
    disaster: 'Disaster',
    policyReason: 'Please enter the reason',
  },
  claimDetails: {
    claimDetails: 'Claim Details',
    claimNotAvailabale: 'Claims Not Available',
    dateLoss: 'Date Loss',
    dateFile: 'Date Filed',
    provider: 'Carrier',
    carrierName: 'Carrier Name',
    providerClaimNo: 'Provider Claim No',
    claimPolicyID: 'Claim Plan Id',
    claimNumber: 'Claim Id',
    rejectedDate: 'Rejected Date',
    cost: 'Cost:',
    claimAmount: 'Claim Amount',
    attachmentImages: 'Attachment Images:',
    userNotes: 'User Notes: ',
    attachmentPdf: 'Attachment PDF:',
    myInfo: 'My Info',
    adminInfo: 'Admin Info',
    DRIVER_LICENSE: 'Driver License',
    REGISTRATION_DOC: 'Registration Doc',
    INSURANCE_DECLARATION: 'Insurance Declaration Pages',
    CLAIM_INFO: 'Claim Info',
    INCIDENT_REPORT: 'Incident Report',
    OTHER_MISC: 'Other / Misc',
  },
  forgotPassword: {
    send: 'Send',
    forgotPassword: 'Forgot Password?',
    forgotMessage:
      'Don’t worry we’ve got your back. Please enter your email address to reset your password.',
    backToLogin: '←  Back to Login ',
  },
  policyStatusEnums: {
    all: 0,
    auto: 1,
    home: 2,
    mobile: 3,
    commercials: 4,
    disaster: 5,
  },
  invoiceTypeEnums: {
    all: 0,
    paid: 1,
    upcoming: 2,
    failed: 3,
  },
  claimStatusEnums: {
    all: 0,
    pending: 1,
    settled: 2,
    cancelled: 3,
    rejected: 4,
  },
  planCategory: {
    month: 'month',
    monthly: 'monthly',
    biannual: 'biannual',
    biannual_: 'Biannual',
    biannually: 'biannually',
    annual: 'annual',
    annually: 'annually',
    rejected: 'Rejected',
    cancelled: 'Cancelled',
    pending: 'Pending',
    settled: 'settled',
  },
  refundCategory: {
    succeed: 'Succeed',
    failed: 'Failed',
    pending: 'Pending',
  },
  deductablePlans: {
    deductable: 'https://noduk.com/insurance/',
    autoDeductablePlan: 'https://noduk.com/service/car-insurance/',
    homeDeductablePlan: 'https://noduk.com/service/home-coverage/',
    commercialDeductablePlan: 'https://noduk.com/service/business-insurance/',
    phoneDeductablePlan: 'https://noduk.com/service/phone-insurance/',
  },
  affiliates: {
    affiliate: 'https://noduk.com/become-an-affiliate/',
    insuranceAgent:
      'https://noduk.com/become-an-agent/?source=insurance%20agent',
    salesAmbassador:
      'https://noduk.com/become-an-agent/?source=Sales%20Ambassador',
    agency: 'https://noduk.com/register-agency/',
    distributionChannel:
      'https://noduk.com/become-an-agent/?source=Distrubtion%20Channel',
    myAccount: 'https://noduk.com/my-account/',
  },
  aboutUs: {
    about: 'https://noduk.com/about-us/',
    testimonials: 'https://noduk.com/testimonials/',
    faqs: 'https://noduk.com/faqs/',
    privacy: 'https://noduk.com/privacy/',
  },
  deleteAccount: {
    deleteDescription: 'Want to delete your account?',
    deleteNote:
      'Do you want to delete your account? Please select the following options.',
    likeReason: 'I did not like the application',
    lookReason: "I couldn't find what I was looking for",
    payReason: 'I did find what I was looking for, but I could not pay',
    relateReason: 'I could not find what I was looking for',
    future:
      'What would you like the app to have so that you can use it again in the future?',
    typeReason: 'Type Reason',
    deleteConitune:
      'To delete your account, Please enter your current password to continue.',
    enterPassword: 'Enter Password',
    deleteNotes:
      'Do you want to delete your account? Once you delete your account your data will also be deleted.',
  },
  feedback: {
    rate: 'Rate Your Experience',
    improved: 'Tell us what can be improved?',
    overallPerfect: 'Overall Perfect!',
    customerSupport: 'Customer Support!',
    speedAndEfficiency: 'Speed and Efficiency',
    bug: 'Bug',
    shareFeedback: 'Share your feedback here',
    submit: 'Submit',
  },
};
export const signupText = {
  formHeadings: {
    step1: 'Personal Details',
    step2: 'Address Details',
    step3: 'Password',
  },
  fieldTypeEnums: {
    streetNumber: `street_number`,
    route: `route`,
    naibhour: `neighborhood`,
    locality: `locality`,
    admin: `administrative_area_level_1`,
    code: `postal_code`,
  },

  ADDRESSKEY: 'AIzaSyDVIN3eonG7yBS2wqv3S6hEBGe3gcrt0AM',
  personalDetails: 'Personal details',
  addressInfo: 'Address Details',
  password: 'Password',
  back: '← Back',
  next: 'Next →',
  submit: 'Create Account',
  nodukSignup: 'No Duk Sign Up',
  login: 'Login',
  description:
    ' Things go wrong. Deductibles are expensive. But we take that burden away with No Duk.',
  customerHeading: 'Over 100,000 customers choose us.',
  signupVideoUrl: 'https://www.youtube.com/embed/OwsSuvguZpo',
  close: 'close',
  errorPlaceholders: {
    firstName: 'Please enter your first name',
    lastName: 'Please enter your last name',
    emailv2: 'Please enter your email address',
    dob: 'Please select your date of birth',
    phone: 'Please enter your phone number',
    address: 'Please enter your address',
    city: 'Please enter your city',
    state: 'Please enter your State',
    zip: 'Please select your zip code',
    password: 'Please enter your password',
    newPassword: 'Please enter new password',
    confirm: 'Password must be matched',
    confirmPassRequires: 'Confirm password is required',
    passwordMatchError: `New and confirm passwords must match`,
    reason: 'Reason is required',
  },
};

export const signinText = {
  login: 'Log in',
  resetPassword: 'Reset Password',
  resetDetails: 'Please create your password to continue using NoDuk.',
  forgotPassword: 'Forgot Password?',
  createAccount: 'Create Account',
  errorPlaceholders: {
    email: 'Please enter your email address',
    password: 'Password is required',
    newPassword: 'New password is required',
  },
};

export const plans = {
  selectPlans: 'Select Your Deductible Plans.',
  vehicle: '+ Auto',
  vehicleIcon: 'Auto Icon',
  devices: '+ Device',
  devicesIcon: 'Devices Icon',
  home: '+ Home',
  homeIcon: 'Home Icon',
  commercial: '+ Commercial',
  disaster: '+ Disaster',
  commercialIcon: 'Commercial Icon',
  addYourPlans: 'Add Your Plan',
  addVehicle: 'Your Auto',
  addDevice: 'Your Device',
  addHome: 'Your Home',
  addCommercial: 'Your Commercial',
  selectCriteria: 'Select Carrier',
  carrier: 'Carrier',
  carrier_: 'Carrier*',
  brandName_: 'Brand Name*',
  selectMaker: 'Select Maker',
  maker: ' Maker',
  maker_: ' Maker*',
  enterMaker: ' Enter Maker',
  selectModel: 'Select Model',
  model: ' Model',
  model_: ' Model*',
  enterModel: ' Enter Model',
  year: 'Year ',
  year_: 'Year* ',
  enterYear: 'Enter Year ',
  deductibles: ' Deductibles',
  deductibles_: ' Deductibles*',
  otherDeductibles: 'Other Deductibles',
  otherDeductibles_: 'Other Deductibles*',
  otherDeductiblesAmount: 'Other Deductibles Amount',
  otherDeductiblesAmount_: 'Other Deductibles Amount*',
  otherCarrier: 'Other Carrier',
  otherCarrier_: 'Other Carrier*',
  otherCarrierName: 'Other Carrier Name',
  address: 'Address ',
  selectDeductible: 'Select Deductible',
  selectDisasterDeductible: 'Select Disaster Deductibles',
  AddToCart: 'Add To Cart',
  editCart: 'Edit Cart Item',
  brandName: 'Brand Name',
  enterBrandName: 'Enter Brand Name',
  location: 'Location*',
};
export const cart = {
  cart: 'Cart',
  bracesleft: '(',
  bracesRight: ')',
  alliedInsurance: 'Allied insurance',
  toyota: 'Toyota',
  corolla: 'Corolla',
  year: '2022',
  deductionValueH: '$500',
  deductionValueT: '$1000',
  actualValue: '$15.00',
  cost: 'Cost',
  monthly: 'Monthly',
  totalCost: 'Total Cost',
  perMonth: '(Per Month)',
  payNow: 'Pay Now',
  emptyCart: 'Empty Cart',
  closeIcon: 'lock Icon',
  cartEmpty: 'Your cart is currently empty!',
  emptyCartMessage:
    'Before proceed to checkout you must add some items in your cart.',
  emptyIcon: 'Empty Icon',
  changeDeductibleAmount: 'Change Deductible Amount',
  viewCart: 'View Cart',
};
export const COUPON_TYPE = {
  ONCE: 1,
  FOREVER: 2,
  FIRST_MONTH: 3,
};
