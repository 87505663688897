import React, {useState} from 'react';
import './style.scss';
import {setNotificationStatus} from '../../utils/rest-services';
import {useContext} from 'react';
import ApplicationContext from '../../utils/context-api/context';

export const NotificationItem = ({notify}) => {
  const {notificationCount, setNotificationCount} =
    useContext(ApplicationContext);
  const [isRead, setisRead] = useState(notify.isRead);
  const setNotificationRead = async payload => {
    let response = await setNotificationStatus(payload);
    if (response && response.success) {
      setisRead(true);
      setNotificationCount(notificationCount - 1);
    }
  };

  return (
    <>
      <li
        className={isRead ? 'notify-item' : 'notify-item readNotification'}
        onClick={() => setNotificationRead(notify.notificationId)}>
        <div className="notify-image">
          <span
            className={
              notify?.notifyType == 'refund'
                ? 'icon-refund iconFont'
                : notify.notifyType == 'invoice'
                ? 'icon-invoicePaymentIcon iconFont'
                : notify.notifyType == 'subscription'
                ? 'icon-subscriptionIcon iconFont'
                : notify.notifyType == 'claim'
                ? 'icon-policyClaimIcon iconFont'
                : 'icon-notification iconFont'
            }></span>
        </div>
        <div className="notify-detail">
          <p className="notify-head">{notify.title}</p>
          <p>{notify.description}</p>
        </div>
        <p className="notify-date">{notify.sendDate}</p>
      </li>
    </>
  );
};
