import {
  axiosInstance,
  axiosInstanceWithoutToken,
  axiosMultiPartInstance,
} from './config';

const controllerName = {
  customer: `/api/Customer`,
  user: '/api/User',
  payment: `/api/Payment`,
  admin: '/api/AdminCustomer',
  notification: '/api/Notification',
};

const endpoints = {
  login: `${controllerName.user}/Login`,
  signUpUser: `${controllerName.user}/SignUpUser`,
  carrierMakerByType: `${controllerName.customer}/GetCarriersMakersByType?serviceTypeId=`,
  vehicleModelListById: `${controllerName.customer}/GetVehicleModelListById?serviceTypeId=`,
  deductibleList: `${controllerName.customer}/GetDeductibleList`,
  addToCart: `${controllerName.customer}/AddCartItem`,
  getCartItemList: `${controllerName.customer}/GetCartItemList`,
  getCartCount: `${controllerName.customer}/GetCartCount`,
  createCustomerSubscription: `${controllerName.payment}/CreateCustomerSubscriptionV3`,
  checkoutItems: `${controllerName.customer}/GetCheckOutCartItemsById`,
  stripeSubId: `${controllerName.payment}/SaveSubscriptionDetails?SubId=`,
  removeCartItemById: `${controllerName.customer}/RemoveCartItemById`,
  removeAllCartItems: `${controllerName.customer}/RemoveAllCartItems`,
  getCartitemById: `${controllerName.customer}/GetCartItemById`,
  getCustomerProfile: `${controllerName.user}/GetCustomerProfile`,
  updateCustomerProfile: `${controllerName.user}/UpdateCustomerProfile`,
  requestForgotPassword: `${controllerName.user}/ForgetPassword`,
  updateNumberAndSendCode: `${controllerName.user}/VerifyPhoneNumber?phone=`,
  verifyUserOtp: `${controllerName.user}/VerifyPhoneCode?code=`,
  removeDeviceToken: `${controllerName.user}/RemoveDeviceToken`,
  getCustomerCards: `${controllerName.customer}/GetCardPaymentInfo`,
  getCustomerInvoice: `${controllerName.customer}/GetAllInvoicesByStatus`,
  getPolicies: `${controllerName.customer}/GetPolicies`,
  getClaims: `${controllerName.customer}/GetClaimsByStatus`,
  getSubscription: `${controllerName.payment}/GetSubscriptionsByStatus`,
  getRefund: `${controllerName.payment}/GetRefundsByStatus`,
  updateSubscription: `${controllerName.payment}/UpdateSubscriptionCard`,
  cancelSubscription: `${controllerName.payment}/CancelSubscription`,
  getBillingCards: `${controllerName.customer}/GetCardPaymentInfo`,
  addNewClaim: `${controllerName.customer}/AddNewClaims`,
  getClaimDetails: `${controllerName.customer}/GetClaimDetailById?ClaimId`,
  getInvoiceDetails: `${controllerName.customer}/GetInvoiceDetailById?InvoiceId=`,
  getRefundDetails: `${controllerName.payment}/GetRefundById?RefundId=`,
  deletePaymentCard: `${controllerName.payment}/DeleteBillingCardById?stripePayementId=`,
  getSubscriptionDetails: `${controllerName.payment}/GetSubscriptionsDetails?subId=`,
  getLinkedSubscription: `${controllerName.payment}/GetLinkedSubscriptions?stripePayementId=`,
  addNewBillingCard: `${controllerName.payment}/AddNewBillingCard`,
  cancelPolicyByCartItemId: `${controllerName.payment}/CancelPolicyByCartItemId`,
  changePassword: `${controllerName.user}/ChangePassword`,
  resetPassword: `${controllerName.user}/ResetPassword`,
  verifyEmail: `${controllerName.user}/VerifyEmail`,
  test500: `${controllerName.user}/Test500Error`,
  getTotalCoverages: `${controllerName.customer}/GetTotalCoverages`,
  getPromoCode: `${controllerName.payment}/GetPromoCodeInfo`,
  getSubscriptionPlans: `${controllerName.payment}/GetSubscriptionPlans?subId=`,
  upgradeSubscriptionPlans: `${controllerName.payment}/UpgradeSubscriptions`,
  setAdminPassword: `${controllerName.admin}/set-customer-password`,
  verifyZipCode: `${controllerName.customer}/VerifyZipCode?ZipCode=`,
  getCustomerNotifications: `${controllerName.notification}/GetCustomerNotification`,
  setNotificationStatus: `${controllerName.notification}/UpdateCustomerNotificationStatus`,
  deleteAccount: `${controllerName.user}/DeleteAccount`,
  feedBack: `${controllerName.customer}/SaveFeedBack`,
};

export const deleteAccount = async payLoadObject => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.deleteAccount}`,
      payLoadObject,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const addFeedback = async payLoadObject => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.feedBack}`,
      payLoadObject,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const performLogin = async payloadObject => {
  try {
    const response = await axiosInstance.post(
      `${endpoints.login}`,
      payloadObject,
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const resetUserPassword = async payloadObject => {
  try {
    const response = await axiosInstance.post(
      `${endpoints.resetPassword}`,
      payloadObject,
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const userSignUp = async payloadObject => {
  try {
    const response = await axiosMultiPartInstance.post(
      `${endpoints.signUpUser}`,
      payloadObject,
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const getCarrierMakerByType = async serviceTypeId => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.carrierMakerByType}${serviceTypeId}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getPlansSubscription = async id => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getSubscriptionPlans}${id}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const upgradeSubscriptionplans = async body => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.upgradeSubscriptionPlans}`, body)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getVehicleModelListById = async (serviceTypeId, makeId) => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.vehicleModelListById}${serviceTypeId}&makeId=${makeId}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};
export const verfyZipCode = async zipCode => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.verifyZipCode}${zipCode}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getDeductibleList = async isDisaster => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.deductibleList}?isDisaster=${isDisaster || false}`,
      )
    ).data;
  } catch ({response: {data}}) {
    return data;
  }
};
export const addItemToCart = async payloadObject => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.addToCart}`, payloadObject)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getCartItemList = async () => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getCartItemList}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getCartCount = async () => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getCartCount}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const CreateSubscription = async payloadObject => {
  try {
    let response = await axiosInstance.post(
      `${endpoints.createCustomerSubscription}`,
      payloadObject,
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const removeCartItemById = async cartItemId => {
  try {
    return await (
      await axiosInstance.post(
        `${endpoints.removeCartItemById}?cartItemId=${cartItemId}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};
export const getCheckoutItems = async () => {
  try {
    let response = await axiosInstance.get(`${endpoints.checkoutItems}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const removeAllCartItems = async () => {
  try {
    return await (
      await axiosInstance.post(`${endpoints.removeAllCartItems}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const requestForgotPassword = async email => {
  try {
    return await (
      await axiosInstance.post(
        `${endpoints.requestForgotPassword}?email=${email}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};

export const AddStripeSubId = async subId => {
  try {
    let response = await axiosInstance.post(`${endpoints.stripeSubId}${subId}`);
    return response;
  } catch (error) {
    return error;
  }
};
export const getCartItemById = async cartItemId => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.getCartitemById}?cartItemId=${cartItemId}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};
export const getProfileCustomer = async () => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getCustomerProfile}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const updateCustomerProfile = async data => {
  try {
    return await (
      await axiosMultiPartInstance.post(
        `${endpoints.updateCustomerProfile}`,
        data,
      )
    ).data;
  } catch (error) {
    return error;
  }
};
export const getPaymentDefaultCards = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.getCustomerCards}`);
    return result;
  } catch (error) {
    return error;
  }
};
export const getCustomersInvoices = async data => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.getCustomerInvoice}`,
      data,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const setCustomerAdminPassword = async data => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.setAdminPassword}`,
      data,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const getPolicy = async payLoadObj => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.getPolicies}`,
      payLoadObj,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const getClaims = async payLoadObj => {
  try {
    let result = await axiosInstance.post(`${endpoints.getClaims}`, payLoadObj);
    return result;
  } catch (error) {
    return error;
  }
};
export const getAllSubscriptions = async payLoadObj => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.getSubscription}`,
      payLoadObj,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const getAllRefund = async payLoadObj => {
  try {
    let result = await axiosInstance.post(`${endpoints.getRefund}`, payLoadObj);
    return result;
  } catch (error) {
    return error;
  }
};
export const updateSubscription = async payLoadObj => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.updateSubscription}`,
      payLoadObj,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const cancelSubscription = async payLoadObj => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.cancelSubscription}`,
      payLoadObj,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const getBillingCards = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.getBillingCards}`);
    return result;
  } catch (error) {
    return error;
  }
};
export const deleteBillingCard = async id => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.deletePaymentCard}${id}`,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const cancelPolicyByCartItem = async payLoad => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.cancelPolicyByCartItemId}`,
      payLoad,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const getLinkedSubscriptions = async id => {
  try {
    let result = await axiosInstance.get(
      `${endpoints.getLinkedSubscription}${id}`,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const getClaimDetailsById = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.getClaimDetails}=${id}`);
    return result;
  } catch (error) {
    return error;
  }
};
export const getInvoiceById = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.getInvoiceDetails}${id}`);
    return result;
  } catch (error) {
    return error;
  }
};
export const getRefundById = async id => {
  try {
    let result = await axiosInstance.get(`${endpoints.getRefundDetails}${id}`);
    return result;
  } catch (error) {
    return error;
  }
};
export const getSubscriptionById = async id => {
  try {
    let result = await axiosInstance.get(
      `${endpoints.getSubscriptionDetails}${id}`,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const addNewPaymentCard = async data => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.addNewBillingCard}`,
      data,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const addNewClaims = async data => {
  try {
    let result = await axiosMultiPartInstance.post(
      `${endpoints.addNewClaim}`,
      data,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const requestChangePassword = async payLoad => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.changePassword}`,
      payLoad,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const requestVerifyEmail = async payLoad => {
  try {
    let result = await axiosInstance.post(`${endpoints.verifyEmail}`, payLoad);
    return result;
  } catch (error) {
    return error;
  }
};
export const testing500 = async () => {
  try {
    let result = await axiosInstance.get(`${endpoints.test500}`);
    return result;
  } catch (error) {
    return error;
  }
};
export const sendLatestCodeToNumber = async (phone, token) => {
  try {
    axiosInstanceWithoutToken.defaults.headers.common[
      'Authorization'
    ] = `bearer ${token}`;
    let result = await axiosInstanceWithoutToken.post(
      `${endpoints.updateNumberAndSendCode}${phone}`,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const editPhoneNumber = async phone => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.updateNumberAndSendCode}${phone}`,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const verifyUserOtpWithoutToken = async (otp, phone) => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.verifyUserOtp}${otp}&phone=${phone}`,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const verifyUserOtp = async (otp, token, phone) => {
  try {
    axiosInstanceWithoutToken.defaults.headers.common[
      'Authorization'
    ] = `bearer ${token}`;
    let result = await axiosInstanceWithoutToken.post(
      `${endpoints.verifyUserOtp}${otp}&phone=${phone}`,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const removeDeviceTokenAPI = async token => {
  try {
    let result = await axiosInstance.post(
      `${endpoints.removeDeviceToken}`,
      token,
    );
    return result;
  } catch (error) {
    return error;
  }
};
export const getCoverages = async () => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getTotalCoverages}`)
    ).data;
  } catch ({response: {data}}) {
    return data;
  }
};
export const getPromoCodeInformation = async couponCode => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getPromoCode}?code=${couponCode}`)
    ).data;
  } catch (error) {
    return error;
  }
};
export const getAllNotifications = async () => {
  try {
    return await (
      await axiosInstance.get(`${endpoints.getCustomerNotifications}`)
    ).data;
  } catch (error) {
    return error;
  }
};

export const setNotificationStatus = async id => {
  try {
    return await (
      await axiosInstance.get(
        `${endpoints.setNotificationStatus}?notifyId=${id}`,
      )
    ).data;
  } catch (error) {
    return error;
  }
};
